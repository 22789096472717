<template>
	<div class="p-2 bg-gray-200 flex flex-col rounded-lg" @keyup.delete="emit('delete')">
		<div class="flex">
			<div class="flex items-center gap-4 flex-wrap">
				<div class="flex gap-1 items-center h-6">
					<input
						ref="startRef"
						v-model="localStart"
						type="time"
						class="p-0 text-center bg-white border-none rounded w-fit px-1 invalid:text-red-600 min-w-24"
						:max="localEnd"
						step="1800"
						value="09:00"
					/>
					<label for="start"></label>
					-<input
						v-model="localEnd"
						type="time"
						class="p-0 text-center bg-white border-none rounded w-fit px-1 invalid:text-red-600 min-w-24"
						:min="localStart"
						step="1800"
						value="17:00"
					/>
				</div>
				<div class="flex gap-1 font-semibold text-sm">
					<button
						v-for="(DAY, i) in DAYS_OF_WEEK"
						:key="DAY"
						class="w-6 h-6 rounded grid place-items-center"
						:class="localRecurrences[i] ? 'bg-orange-500' : 'bg-white'"
						:aria-label="
							DAY + ' ' + (localRecurrences[i] ? 'available' : 'unavailable')
						"
						@click="localRecurrences.splice(i, 1, !localRecurrences[i])"
					>
						{{ DAY.charAt(0) }}
					</button>
				</div>
				<div class="flex gap-1">
					<div v-for="officeLocation in OFFICE_LOCATIONS" :key="officeLocation">
						<label
							:for="`collapsed-${officeLocation}-radio-input-${uid}`"
							class="font-semibold rounded-full px-2 h-6 block cursor-pointer"
							:class="officeLocation === localLocation ? 'bg-orange-500' : 'bg-white'"
						>
							<input
								:id="`collapsed-${officeLocation}-radio-input-${uid}`"
								v-model="localLocation"
								type="radio"
								:value="officeLocation"
								name="collapsed-office-locations"
								class="h-0 w-0 opacity-0 absolute"
								:checked="
									localLocation?.toLowerCase() === officeLocation.toLowerCase()
								"
							/>
							{{ officeLocation }}
						</label>
					</div>
					<button
						class="rounded-full px-2 gap-1 h-6 flex capitalize items-center font-semibold"
						:class="
							communicationMediums.includes('phone') ? 'bg-orange-500' : 'bg-white'
						"
						@click="togglePhoneAvailability()"
					>
						<FAIcon icon="phone" class="text-xs" /><span class="text-sm">Phone</span>
					</button>
				</div>
			</div>
			<button
				class="ml-auto mb-auto px-1 transition-transform ease-out duration-500"
				:class="expanded && 'rotate-180'"
				@click="expanded = !expanded"
			>
				<FAIcon icon="chevron-down" />
			</button>
		</div>
		<BaseExpand :expanded="expanded">
			<div class="p-4 rounded bg-white mt-2 flex flex-wrap gap-4">
				<div>
					<h4 class="font-semibold text-gray-500 tracking-wider uppercase mb-1">
						Repeat On
					</h4>
					<div class="flex gap-1 font-semibold text-sm">
						<button
							v-for="(DAY, i) in DAYS_OF_WEEK"
							:key="DAY"
							class="w-6 h-6 rounded grid place-items-center"
							:class="localRecurrences[i] ? 'bg-orange-500' : 'bg-gray-100'"
							@click="localRecurrences.splice(i, 1, !localRecurrences[i])"
						>
							{{ DAY.charAt(0) }}
						</button>
					</div>
				</div>
				<div>
					<h4 class="font-semibold text-gray-500 tracking-wider uppercase mb-1">
						Location
					</h4>
					<div class="flex gap-2">
						<div v-if="OFFICE_LOCATIONS.length" class="px-2 py-1 rounded bg-gray-100">
							<fieldset>
								<legend class="font-semibold text-sm">In Person</legend>
								<div class="flex flex-wrap gap-x-4">
									<RadioInput
										v-for="officeLocation in OFFICE_LOCATIONS"
										:key="officeLocation"
										:value="officeLocation"
										name="expanded-office-locations"
										class="capitalize"
										:checked="
											localLocation?.toLowerCase() ===
											officeLocation.toLowerCase()
										"
										@select="localLocation = officeLocation"
									>
										{{ officeLocation }}
									</RadioInput>
								</div>
							</fieldset>
						</div>
						<div class="px-2 py-1 rounded bg-gray-100">
							<h5 class="font-semibold text-sm">Virtually</h5>
							<div class="flex flex-wrap gap-x-4">
								<CheckboxInput
									:name="`phone-${uid}`"
									:checked="communicationMediums.includes('phone')"
									@update:checked="togglePhoneAvailability()"
								>
									Phone
								</CheckboxInput>
							</div>
						</div>
					</div>
				</div>
				<BaseButton color="red" class="mt-auto ml-auto" @click="emit('delete')"
					>Delete</BaseButton
				>
			</div>
		</BaseExpand>
	</div>
</template>
<script setup>
import { ref, computed } from 'vue';
import BaseExpand from '@/components/ui/BaseExpand.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import CheckboxInput from '@/components/ui/CheckboxInput.vue';
import RadioInput from '@/components/ui/RadioInput.vue';

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const startRef = ref(null);
defineExpose({
	startRef,
});

const emit = defineEmits(['update:start', 'update:end', 'delete']);
const props = defineProps({
	start: { type: String, required: false },
	end: { type: String, required: false },
	recurrences: { type: Array, required: false },
	location: { type: String, required: false },
	communicationMediums: { type: Array, required: true },
});

const localStart = computed({
	get() {
		return props.start;
	},
	set(newValue) {
		emit('update:start', newValue);
	},
});
const localEnd = computed({
	get() {
		return props.end;
	},
	set(newValue) {
		emit('update:end', newValue);
	},
});
const localRecurrences = ref(props.recurrences);
const localLocation = computed({
	get() {
		return props.location;
	},
	set(newValue) {
		emit('update:location', newValue);
	},
});
const localCommunicationMediums = computed({
	get() {
		return props.communicationMediums;
	},
	set(newValue) {
		emit('update:communicationMediums', newValue);
	},
});

const expanded = ref(false);
const uid = ref(crypto.randomUUID());

const OFFICE_LOCATIONS = ref(['Normal', 'Green Bay']);

function togglePhoneAvailability() {
	if (localCommunicationMediums.value.includes('phone')) {
		localCommunicationMediums.value.splice(localCommunicationMediums.value.indexOf('phone'), 1);
	} else {
		localCommunicationMediums.value.push('phone');
	}
}
</script>
