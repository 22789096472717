<template>
	<div class="grid gap-1">
		<div class="flex gap-2 px-2">
			<div class="w-32 shrink-0"></div>
			<div class="flex w-full justify-between gap-1">
				<span v-for="tsl in timeSlotLabels" :key="tsl" class="w-full pl-1 border-l-2">
					{{ tsl }}
				</span>
			</div>
		</div>
		<CoverageRow
			v-for="row in schedule.coverageView"
			:key="row.day"
			:schedule="schedule"
			:day-label="row.day"
			:time-slots="row.timeSlots"
			:team-members="row.teamMembers"
		/>
	</div>
</template>
<script setup>
import CoverageRow from '@/components/appointments/CoverageRow.vue';

defineProps({
	schedule: {
		type: Object,
		required: true,
	},
});

const timeSlotLabels = ['8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm'];
</script>
