<template>
	<transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
		<template v-if="expanded">
			<div>
				<slot />
			</div>
		</template>
	</transition>
</template>
<script setup>
defineProps({
	expanded: { type: Boolean, default: false },
});
function enter(element) {
	const width = getComputedStyle(element).width;

	element.style.width = width;
	element.style.position = 'absolute';
	element.style.visibility = 'hidden';
	element.style.height = 'auto';

	const height = getComputedStyle(element).height;

	element.style.width = null;
	element.style.position = null;
	element.style.visibility = null;
	element.style.height = 0;

	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = height;
	});
}

function afterEnter(element) {
	element.style.height = 'auto';
}

function leave(element) {
	const height = getComputedStyle(element).height;

	element.style.height = height;

	getComputedStyle(element).height;

	requestAnimationFrame(() => {
		element.style.height = 0;
	});
}
</script>
<style scoped lang="scss">
* {
	will-change: height;
	transform: translateZ(0);
	backface-visibility: hidden;
	perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
	transition: height 0.5s cubic-bezier(0.25, 1, 0.5, 1);
	overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
	height: 0;
}
</style>
