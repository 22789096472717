<template>
	<div class="flex flex-col items-stretch">
		<figure height="100%" class="mx-auto w-full overflow-hidden rounded text-center">
			<img
				v-if="previous_value"
				:src="previous_value"
				alt="Current Banner"
				class="bg-checkerboard w-full"
			/>
			<figcaption
				class="bg-blue-700 p-4 font-bold tracking-wide text-white"
				:class="previous_value ? ' bg-blue-700' : 'bg-red-700 '"
			>
				{{ previous_value ? '' : 'No' }} Last {{ label }} Reviewed
			</figcaption>
		</figure>

		<figure class="mx-auto mt-2 w-full overflow-hidden rounded text-center">
			<img :src="value" alt="New Banner" class="bg-checkerboard w-full" />
			<figcaption class="bg-blue-700 p-4 font-bold tracking-wide text-white">
				New {{ label }}
			</figcaption>
		</figure>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	// eslint-disable-next-line vue/prop-name-casing
	previous_value: { type: String },
	value: { type: String, required: true },
	// eslint-disable-next-line vue/prop-name-casing
	override_type: { type: String, required: true },
});

const label = computed(() => {
	switch (props.override_type) {
		case 'office_banner':
			return 'Office Banner';

		case 'more_info_image':
			return 'Locations Image';
		default:
			return 'Image';
	}
});
</script>
