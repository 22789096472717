<template>
	<div class="rounded-xl p-3 bg-gray-100 flex flex-col" @keyup.delete="emit('delete')">
		<div v-if="!isLoading">
			<div class="justify-between items-center flex gap-2">
				<div class="text-sm text-gray-700 space-y-1">
					<div class="flex items-center whitespace-nowrap">
						<FAIcon icon="fas fa-calendar" class="mr-2" />
						<h3 v-if="!ranged">
							{{ formatDate(date) }}
						</h3>
						<h3 v-else>{{ formatDate(startDate) }} - {{ formatDate(endDate) }}</h3>
						<FAIcon
							v-if="repeatsAnnually"
							icon="fas fa-repeat"
							size="sm"
							class="ml-1"
						/>
					</div>
					<div class="flex items-center gap-2">
						<FAIcon icon="fas fa-clock" />
						<div v-if="allDay">All day</div>
						<div v-else-if="start && end" class="flex gap-1">
							<div>{{ formatTime(start) }}</div>
							<span>-</span>
							<div>{{ formatTime(end) }}</div>
						</div>
						<div v-else>Invalid</div>
					</div>
					<div class="flex gap-2 items-center">
						<FAIcon icon="fas fa-user" />
						<div v-if="associateIds.length === staff.length">Everybody</div>
						<div v-else-if="associateIds.length === 0">Nobody</div>
						<div v-else-if="associateIds.length <= 10" class="flex flex-wrap">
							<SFProfileImage
								v-for="associateId in associateIds"
								:key="associateId"
								:associate_id="associateId"
								width="20px"
								color="white"
								class="-mr-1"
							/>
						</div>
						<div v-else>{{ associateIds.length }} team members</div>
					</div>
				</div>
				<div class="flex flex-col items-end gap-1">
					<BaseDialog ref="editDialogRef" persistent>
						<template #header>
							<h2>Editing</h2>
						</template>
						<template #activator="{ openDialog }">
							<BaseButton color="darkGray" text dense @click="openDialog">
								Edit <FAIcon icon="fas fa-pencil" />
							</BaseButton>
						</template>
						<template #default="{ closeDialog }">
							<form @submit.prevent="save">
								<div class="flex gap-8 rounded-lg p-2">
									<div class="flex flex-col gap-4">
										<div class="grid">
											<h4
												class="text-sm font-semibold uppercase tracking-wider text-gray-500 mb-1"
											>
												Date
											</h4>
											<div class="grid">
												<div
													v-if="localRanged"
													class="flex items-end gap-2"
												>
													<div class="grid">
														<label
															:for="`startDate-${uid}`"
															class="text-xs text-gray-600"
															>Start date</label
														>
														<input
															v-model="localStartDate"
															type="date"
															:name="`startDate-${uid}`"
															class="w-fit rounded bg-white !p-1 text-center border-gray-500 focus:ring focus:ring-orange-500 invalid:border-red-500 invalid:text-red-600"
															:max="localEndDate"
														/>
													</div>
													<span>-</span>
													<div class="grid">
														<label
															:for="`endDate-${uid}`"
															class="text-xs text-gray-600"
															>End date</label
														>
														<input
															v-model="localEndDate"
															type="date"
															:name="`endDate-${uid}`"
															class="w-fit rounded bg-white !p-1 text-center border-gray-500 focus:ring focus:ring-orange-500 invalid:border-red-500 invalid:text-red-600"
															:min="localStartDate"
														/>
													</div>
												</div>
												<div v-else class="grid">
													<label
														:for="`date-${uid}`"
														class="text-xs text-gray-600"
														>Date</label
													>
													<input
														v-model="localDate"
														type="date"
														:name="`date-${uid}`"
														class="w-fit rounded bg-white !p-1 text-center border-gray-500 focus:ring focus:ring-orange-500 invalid:border-red-500 invalid:text-red-600"
														:min="
															new Date().toISOString().split('T')[0]
														"
													/>
												</div>
												<CheckboxInput
													:checked.sync="localRanged"
													:name="`ranged-${uid}`"
													class="mt-1"
												>
													Multiple days
												</CheckboxInput>
												<CheckboxInput
													:checked.sync="localRepeatsAnnually"
													:name="`repeatsAnnually-${uid}`"
												>
													Repeats annually
												</CheckboxInput>
											</div>
										</div>
										<div class="mb-2 grid min-w-56">
											<h4
												class="text-sm font-semibold uppercase tracking-wider text-gray-500 mb-1"
											>
												Time
											</h4>
											<BaseExpand :expanded="!localAllDay">
												<div class="flex items-end gap-1 mb-2">
													<div class="grid">
														<label
															:for="`outOfOfficeBlockStartTime-${uid}`"
															class="text-xs text-gray-600"
															>Start</label
														>
														<input
															v-model="localStart"
															:name="`outOfOfficeBlockStartTime-${uid}`"
															type="time"
															class="w-fit min-w-24 rounded bg-white !p-1 text-center border-gray-500 focus:ring focus:ring-orange-500 invalid:border-red-500 invalid:text-red-600"
															:max="localEnd"
															value="09:00"
														/>
													</div>
													-
													<div class="grid">
														<label
															:for="`outOfOfficeBlockEndTime-${uid}`"
															class="text-xs text-gray-600"
															>End</label
														>
														<input
															v-model="localEnd"
															:name="`outOfOfficeBlockEndTime-${uid}`"
															type="time"
															class="w-fit min-w-24 rounded bg-white !p-1 text-center border-gray-500 focus:ring focus:ring-orange-500 invalid:border-red-500 invalid:text-red-600"
															:min="localStart"
															value="17:00"
														/>
													</div>
												</div>
											</BaseExpand>
											<div class="grid">
												<CheckboxInput
													:checked.sync="localAllDay"
													:name="`all-day-${uid}`"
												>
													All day
												</CheckboxInput>
											</div>
										</div>
									</div>
									<div>
										<CheckboxGroup
											:name="`who-${uid}`"
											legend="who"
											:options="staff"
											item-key="associate_id"
											item-text-key="full_name"
											item-value-key="associate_id"
											:selected-values.sync="localWho"
											dense
											column
											bulk-select
										/>
									</div>
								</div>
								<div class="flex justify-end gap-2 mt-2">
									<BaseButton color="red" @click="emit('delete', pk)">
										Delete
									</BaseButton>
									<BaseButton color="gray" @click="cancel(closeDialog)"
										>Cancel</BaseButton
									>
									<BaseButton type="submit" @click.prevent="save(closeDialog)"
										>Save</BaseButton
									>
								</div>
							</form>
						</template>
					</BaseDialog>
					<BaseButton color="red" text dense @click="emit('delete', pk)">
						Delete <FAIcon icon="fas fa-trash" />
					</BaseButton>
				</div>
			</div>
		</div>
		<ProgressBar v-else text="Loading staff data..." />
	</div>
</template>
<script setup>
import { format } from 'date-fns';
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import BaseExpand from '@/components/ui/BaseExpand.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import CheckboxInput from '@/components/ui/CheckboxInput.vue';
import CheckboxGroup from '@/components/ui/CheckboxGroup.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import SFProfileImage from '@/components/common/SFProfileImage.vue';
import useStaffStore from '@/stores/agent/agent-staff';

const staffStore = useStaffStore();
const { staff, isLoading } = storeToRefs(staffStore);

const emit = defineEmits(['save']);
const props = defineProps({
	pk: { type: Number, required: true },
	ranged: { type: Boolean, default: false },
	date: { type: String, required: false },
	startDate: { type: String, required: false },
	endDate: { type: String, required: false },
	repeatsAnnually: { type: Boolean, required: true },
	start: { type: String, required: false },
	end: { type: String, required: false },
	allDay: { type: Boolean, required: true },
	associateIds: { type: Array, required: false },
});

const localRanged = ref(props.ranged);
const localDate = ref(props.date);
const localStartDate = ref(props.startDate);
const localEndDate = ref(props.endDate);
const localRepeatsAnnually = ref(props.repeatsAnnually);
const localAllDay = ref(props.allDay);
const localStart = ref(props.start);
const localEnd = ref(props.end);
const localWho = ref(props.associateIds);
const editDialogRef = ref(null);

const uid = ref(crypto.randomUUID());

function formatDate(dateString) {
	const date = new Date(dateString);
	const options = { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC' };
	return date.toLocaleString('en-US', options);
}
function formatTime(timeString) {
	try {
		const date = new Date(`2000-01-01T${timeString}`);
		return format(date, 'h:mm a');
	} catch (error) {
		console.error('Error formatting time:', error);
		return 'NA';
	}
}
function cancel(closeDialog) {
	localRanged.value = props.ranged;
	localDate.value = props.date;
	localStartDate.value = props.startDate;
	localEndDate.value = props.endDate;
	localRepeatsAnnually.value = props.repeatsAnnually;
	localAllDay.value = props.allDay;
	localStart.value = props.start;
	localEnd.value = props.end;
	localWho.value = props.associateIds;
	closeDialog(true);
}
function save(closeDialog) {
	const outOfOfficeBlock = {
		pk: props.pk,
		ranged: localRanged.value,
		date: !localRanged.value ? localDate.value : null,
		startDate: localRanged.value ? localStartDate.value : null,
		endDate: localRanged.value ? localEndDate.value : null,
		repeatsAnnually: localRepeatsAnnually.value,
		allDay: localAllDay.value,
		start: localStart.value,
		end: localEnd.value,
		associateIds: localWho.value,
	};

	emit('save', outOfOfficeBlock);
	closeDialog(true);
}
async function openEditDialog() {
	await staffStore.get();
	editDialogRef.value.openDialog();
}

onMounted(async () => {
	await staffStore.get();
});

defineExpose({
	openEditDialog,
});
</script>
