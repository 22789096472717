<template>
	<li :aria-label="ariaLabel" class="group relative w-full max-w-full self-start">
		<div
			ref="target"
			class="group relative flex aspect-[6/1] min-h-36 w-full items-center justify-center overflow-hidden rounded-sm border-4 border-solid bg-gray-100 transition-colors focus-within:ring-2 focus-within:ring-orange-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 group-hover:border-orange-500 dark:border-gray-500 dark:bg-gray-500 md:min-h-52 lg:min-h-36 2xl:min-h-36"
		>
			<img
				v-if="targetIsVisible || loaded"
				:src="secure_url"
				alt=""
				class="rounded-xs pointer-events-none absolute left-1/2 top-0 block aspect-[6/1] h-full min-w-full !max-w-[unset] -translate-x-1/2 object-cover opacity-90 transition-opacity group-hover:opacity-100"
				loading="lazy"
				@load="loaded = true"
			/>

			<ProgressBar v-else />

			<button
				type="button"
				class="absolute left-0 top-0 z-10 h-full w-full focus:outline-none"
				@click="toggleExpansion"
				@keyup.enter="toggleExpansion"
			>
				<span class="sr-only">
					{{ expanded ? 'Shrink' : 'Enlarge' }} image tagged with
					{{ listFormatter.format(tags) }}
				</span>
			</button>
			<FAIcon
				v-if="current"
				icon="check"
				class="absolute right-3 top-3 h-4 w-4 rounded-full bg-green-500 p-1.5 text-xl text-white"
				:class="expanded ? 'p-2 text-3xl' : 'p-1 text-xl'"
			/>
		</div>

		<p class="mb-0 text-left text-lg">Tags:</p>
		<div
			:class="{ '!opacity-100': expanded }"
			class="flex flex-wrap items-start gap-2 pb-2 opacity-30 transition-opacity group-focus-within:opacity-100 group-hover:opacity-100"
		>
			<BaseButton
				v-for="tag in filteredTags"
				:key="tag"
				:color="selectedTags.includes(tag) ? 'orange' : 'gray'"
				:outline="!selectedTags.includes(tag)"
				class="shrink-0"
				dense
				@keyup.enter.stop.prevent="toggleTag(tag)"
				@keyup.space.stop.prevent="toggleTag(tag)"
				@click.stop="toggleTag(tag)"
			>
				<template v-if="selectedTags.includes(tag)" #prepend>
					<FAIcon icon="check" />
				</template>
				<span class="sr-only">
					{{ selectedTags.includes(tag) ? 'Stop filtering' : 'Filter' }} images by tag:
				</span>
				{{ capitalize(tag) }}
			</BaseButton>
		</div>
		<p v-if="eligibleStates.length > 0" class="mt-4 text-xs text-gray-500">
			This image is eligible for offices in these states:
			{{ listFormatter.format(eligibleStates) }}
		</p>
		<div v-if="expanded" class="flex justify-end gap-2">
			<BaseButton color="gray" :disabled="savingChanges" @click="$emit('cancel')">
				Cancel
			</BaseButton>
			<BaseButton
				color="primary"
				:disabled="current || !loaded || savingChanges"
				@click.stop="selectBanner"
			>
				<span v-if="savingChanges" class="animate-pulse">Selecting</span>
				<span v-else>Select this banner</span>
			</BaseButton>
		</div>
	</li>
</template>

<script setup>
import { states } from '@/components/payment/constants';

import { computed, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useIntersectionObserver } from '@vueuse/core';

import { capitalize, listFormatter } from '@/utils';

import useEditorStore from '@/stores/editor';
import useEditorChangesStore from '@/stores/editorChanges';

import BaseButton from '@/components/ui/BaseButton';
import ProgressBar from '@/components/ui/ProgressBar.vue';

const emit = defineEmits(['update:selectedTags', 'selected', 'expand', 'cancel']);

const props = defineProps({
	// eslint-disable-next-line vue/prop-name-casing
	secure_url: {
		type: String,
		required: true,
	},
	tags: {
		type: Array,
		default: () => [],
	},

	current: {
		type: Boolean,
		default: false,
	},
	expanded: {
		type: Boolean,
		default: false,
	},
	selected: {
		type: Boolean,
		default: false,
	},
	selectedTags: {
		type: Array,
		required: true,
	},
});

const editorStore = useEditorStore();
const editorChangesStore = useEditorChangesStore();
const { savingChanges } = storeToRefs(editorChangesStore);

const ariaLabel = computed(() => `image with tags ${props.tags.join(', ')}`);
const loaded = ref(false);

const target = ref(null);
const targetIsVisible = ref(false);

// const stateAbbreviations = computed(()=>states.map(state=>state.value))
const stateNames = computed(() =>
	states.map(state => state.text.replace(/\S+ - /, '').toUpperCase())
);

const filteredTags = computed(() => {
	if (props.tags.includes('ADT')) {
		return props.tags.filter(tag => !stateNames.value.includes(tag.toUpperCase()));
	}
	return props.tags;
});
const eligibleStates = computed(() => {
	if (!props.tags.includes('ADT')) {
		return [];
	}
	return props.tags.filter(tag => stateNames.value.includes(tag));
});

const { stop } = useIntersectionObserver(target, ([{ isIntersecting }]) => {
	targetIsVisible.value = isIntersecting;
});
function toggleExpansion() {
	emit(props.expanded ? 'cancel' : 'expand');
}

function toggleTag(tag) {
	const tagSet = new Set(props.selectedTags);

	if (tagSet.has(tag)) {
		tagSet.delete(tag);
	} else {
		tagSet.add(tag);
	}
	emit('update:selectedTags', [...tagSet]);
}

async function selectBanner() {
	editorChangesStore.addChange({
		type: 'office_banner',
		value: props.secure_url,
		status: 'approved',
		key: 'office.banner_bg_url',
	});
	await editorChangesStore.saveSingleChange({ key: 'office.banner_bg_url' });
	await editorStore.updateConfig();
	emit('selected');
}

onUnmounted(stop);
</script>

<style lang="scss">
.card {
	transition: width 100ms ease-in-out;

	&.expanded {
		width: 100%;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
