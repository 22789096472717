<template>
	<div class="w-full bg-gray-100 rounded-xl grid p-2 gap-2">
		<div class="justify-between w-full flex">
			<div class="flex gap-2 items-center">
				<h3 class="font-semibold">{{ name }}</h3>
			</div>
			<button
				class="bg-gray-200 px-2 font-semibold rounded-md hover:bg-gray-300 transition-colors"
				@click="createBlock()"
			>
				+ Add availability block
			</button>
		</div>
		<AvailabilityBlock
			v-for="(ab, i) in localAvailabilityBlocks"
			ref="abRefs"
			:key="ab.pk"
			:start.sync="ab.start"
			:end.sync="ab.end"
			:recurrences.sync="ab.recurrences"
			:communication-mediums.sync="ab.communicationMediums"
			:location.sync="ab.location"
			@delete="deleteBlock(i)"
		/>
	</div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import AvailabilityBlock from '@/components/appointments/AvailabilityBlock.vue';

const props = defineProps({
	associateId: {
		type: String,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
	availabilityBlocks: {
		type: Array,
		default: () => [],
	},
});

const localAvailabilityBlocks = ref(props.availabilityBlocks);

const abRefs = ref([]);

async function createBlock() {
	localAvailabilityBlocks.value.push({
		pk: localAvailabilityBlocks.value.length,
		start: null,
		end: null,
		recurrences: [false, false, false, false, false, false, false],
		communicationMediums: ['phone'],
		location: null,
	});
	await nextTick();
	abRefs.value[abRefs.value.length - 1]?.$refs.startRef.focus();
}

function deleteBlock(idx) {
	localAvailabilityBlocks.value.splice(idx, 1);
}
</script>
