<template>
	<li class="flex flex-col justify-between">
		<button
			class="text-md flex grow items-center justify-between rounded-lg bg-gray-700 px-3 py-2 outline-none transition hover:bg-gray-600 focus:ring-2 focus:ring-orange-500/70"
			:aria-expanded="`${listIsOpen}`"
			:aria-controls="`${$attrs.unique_key}-sidebar-group`"
			@click="listIsOpen = !listIsOpen"
		>
			<span class="flex w-full items-center justify-between gap-x-1">
				<FAIcon v-if="icon" fixed-width size="lg" :icon="icon" class="text-white" />
				<span
					class="ml-2 grow whitespace-nowrap text-left text-lg font-semibold uppercase tracking-widest"
				>
					{{ label }} Pages
				</span>

				<FAIcon
					v-if="subRoutes.length"
					fixed-width
					size="lg"
					icon="angle-down"
					class="ml-2 text-white duration-200"
					:class="{ 'rotate-180': listIsOpen }"
				/>
			</span>
		</button>

		<BaseExpand :expanded="listIsOpen">
			<ul
				:id="`${$attrs.unique_key}-sidebar-group`"
				class="flex flex-col !gap-2 rounded-lg !pl-4"
			>
				<SidebarLink
					v-for="component in sharedComponentList"
					:key="component.path"
					indent="md"
					:label="component.label"
					:icon="component.icon"
					:disabled="editorStore.initializing"
					:exact="false"
					:to="{
						name: 'editor',
						params: { page: component.unique_key, editor: component.path },
					}"
					class="first:mt-2"
				/>
				<li class="flex flex-col gap-2">
					<SidebarSubGroup
						v-for="route in sortedSubRoutes"
						:key="route.id"
						v-bind="{ ...route }"
					/>
				</li>
			</ul>
		</BaseExpand>
	</li>
</template>
<script setup>
import { ref, computed } from 'vue';

import useEditorStore from '@/stores/editor';
import SidebarSubGroup from '@/components/navigation/SidebarSubGroup';
import SidebarLink from '@/components/navigation/SidebarLink';
import { EditorViews } from '@/views/MXEditor/views';
import BaseExpand from '@/components/ui/BaseExpand';

const props = defineProps({
	icon: { type: String, default: undefined },
	domainName: { type: String, default: undefined },
	label: { type: String, default: 'LABEL UNDEFINED' },
	subRoutes: { type: Array, default: () => [] },
});

const listIsOpen = ref(false);

const editorStore = useEditorStore();

const sortedSubRoutes = computed(() => {
	return [...props.subRoutes].sort((a, b) => a.editor.label.localeCompare(b.editor.label));
});

const sharedComponentList = computed(() => {
	const sharedComponents = props.subRoutes.reduce((acc, route) => {
		const components = route.editor.components
			.filter(c => c.group_level === true)
			.map(c => ({ unique_key: route.unique_key, ...c }));

		return [...acc, ...components];
	}, []);

	return sharedComponents
		?.reduce((a, { key, ...rest }) => {
			const ev = EditorViews.find(({ path }) => path === key);

			// only grab components that have a match in the EditorViews
			if (ev) {
				a.push({ ...ev, ...rest });
			}
			return a;
		}, [])
		.sort((a, b) => a.label > b.label);
});
</script>
