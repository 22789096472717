<template>
	<fieldset
		:class="[{ dark, 'w-full': fullWidth, disabled }, pill ? 'rounded-full' : 'rounded']"
		class="group relative flex"
		v-bind="{ disabled }"
	>
		<div :class="{ pill, outlined, transparent }" class="selectWrapper">
			<select
				:id="id.replaceAll(' ', '_')"
				ref="selectElement"
				v-model="localValue"
				:name="id.replaceAll(' ', '_')"
				class="selectElement peer bg-none"
			>
				<option v-if="showDefaultOption" :selected="!value" disabled value="" />
				<option
					v-for="item in localItems"
					:key="JSON.stringify(item)"
					:value="item.value"
					:class="{ capitalize }"
					class="text-black"
					:disabled="item.disabled"
					:selected="value === item.value"
				>
					{{ item?.text ?? item.value ?? item }}
				</option>
				<slot name="append-item" />
			</select>
			<span class="visibleText">
				<span class="ml-3 truncate">
					{{ selectedItem?.text || '' }}
				</span>
				<svg
					class="pointer-events-none ml-auto mr-3 shrink-0 grow-0 stroke-2 opacity-80"
					fill="none"
					stroke="currentColor"
					stroke-linecap="round"
					viewBox="0 0 12 12"
				>
					<path d="M1,5 l5,5 l5,-5" />
				</svg>
			</span>
			<label
				:for="id.replaceAll(' ', '_')"
				:class="[
					showLabel ? '' : 'sr-only',
					disabled ? 'text-gray-500' : 'text-gray-600',
					!selectedItem ? 'left-3 top-1.5 text-base' : 'text-xs',
				]"
				class="pointer-events-none absolute -top-4 left-0 whitespace-nowrap transition-all peer-focus:-top-4 peer-focus:left-0 peer-focus:text-xs"
			>
				{{ label }}
			</label>
		</div>
		<p
			v-if="$slots.message"
			class="font-med my-1 flex items-center px-2 text-xs text-red-600"
			:class="messageAbsolute ? 'absolute' : ''"
		>
			<slot name="message"></slot>
		</p>
	</fieldset>
</template>

<script setup>
import { ref, computed } from 'vue';

const emit = defineEmits(['update:value']);
const props = defineProps({
	items: { type: [Array], default: () => [], required: true },
	value: { type: [Object, Number, Boolean, String], default: null },

	id: { type: String, required: true },
	label: { type: String, required: true },

	disabled: { type: Boolean, default: false },

	itemTextKey: { type: String, default: 'text' },
	itemValueKey: { type: String, default: 'value' },

	capitalize: { type: Boolean, default: false },
	dark: { type: Boolean, default: false },
	pill: { type: Boolean, default: false },
	transparent: { type: Boolean, default: false },
	showLabel: { type: Boolean, default: false },
	showDefaultOption: { type: Boolean, default: true },
	outlined: { type: Boolean, default: false },
	messageAbsolute: { type: Boolean, default: false },
	fullWidth: { type: Boolean, default: true },

	valueType: {
		type: String,
		required: false,
		default: 'string',
		validator: value => {
			const validTypes = ['object', 'string', 'number', 'boolean'];
			if (value && !validTypes.includes(value)) {
				console.error(`valueType must be one of:`);
				console.warn(validTypes);
				return false;
			} else {
				return true;
			}
		},
	},
});

const selectElement = ref(null);

const selectedItem = computed(() => {
	return localItems.value?.find(item => {
		let itemMatchesProp;
		switch (props.valueType) {
			case 'array':
				itemMatchesProp = item.value === props.value.join(',');
				break;
			case 'object':
				itemMatchesProp =
					item.value === props.value?.[props.itemValueKey] || item.value === props.value;
				break;
			case 'string':
			case 'number':
			case 'boolean':
			default:
				itemMatchesProp = item.valueToEmit === props.value;
		}
		return itemMatchesProp;
	});
});

const localValue = computed({
	get() {
		return selectedItem.value?.value ?? props.value;
	},
	set(newValue) {
		const matchedItem = localItems.value?.find(item => item.value === newValue);
		emit('update:value', matchedItem?.valueToEmit || newValue);
	},
});

const localItems = computed(() =>
	// standardize item list to match template needs
	props.items?.length > 0
		? props.items.map(item => {
				let finalItem = { text: null, value: null, valueToEmit: null };

				switch (props.valueType) {
					case 'array':
						finalItem = {
							text: item.join(', '),
							value: item.join(','),
							valueToEmit: item,
						};
						break;
					case 'object':
						finalItem = {
							...item,
							text:
								item[props.itemTextKey] ??
								item[props.itemValueKey] ??
								JSON.stringify(item),
							value: item?.[props.itemValueKey] ?? item,
							valueToEmit: item,
						};
						break;
					case 'string':
					case 'number':
					case 'boolean':
					default:
						switch (typeof item) {
							case 'object':
								finalItem = {
									text:
										item?.[props.itemTextKey] ??
										item?.[props.itemValueKey] ??
										JSON.stringify(item),
									value: item?.[props.itemValueKey] ?? JSON.stringify(item),
									valueToEmit: item?.[props.itemValueKey] ?? JSON.stringify(item),
								};
								break;
							case 'string':
							case 'number':
							case 'boolean':
							default:
								finalItem = {
									text: item,
									value: item,
									valueToEmit: item,
								};
						}
				}
				return finalItem;
			})
		: props.items
);

function focusSelectElement() {
	selectElement.value.focus();
}

defineExpose({
	localValue,
	localItems,
	focus: focusSelectElement,
});
</script>

<style scoped lang="scss">
.selectElement {
	@apply border-0 opacity-0;
	@apply appearance-none placeholder-transparent sm:text-sm;
}
.visibleText {
	@apply pointer-events-none flex items-center gap-x-1.5 truncate text-left font-bold;
}
.selectElement,
.visibleText {
	@apply col-start-1 row-start-1 cursor-pointer;
}
.selectWrapper {
	@apply grid w-full grid-cols-1 grid-rows-1;
	@apply relative items-center gap-x-1 rounded;
	@apply ring-0 ring-transparent ring-offset-transparent dark:ring-gray-600;
	@apply disabled:cursor-not-allowed disabled:text-gray-300;

	@apply focus-within:outline-none focus-within:ring-2 focus-within:ring-orange dark:focus-within:ring-orange-400;
	&.outlined {
		@apply border border-solid border-gray-500 disabled:border-gray-300;
	}
	&.pill {
		@apply text-ellipsis rounded-full;
	}
	svg {
		pointer-events: none;
		width: clamp(10px, 1em, 0.8ch);
		height: clamp(10px, 1em, 0.8ch);
	}

	.disabled & {
		@apply pointer-events-none cursor-not-allowed;
	}
}
</style>
