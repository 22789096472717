<template>
	<div
		class="px-1 transition-all duration-500 ease-out"
		:class="expanded && 'rounded-lg bg-gray-100 pb-1.5 pt-2'"
	>
		<div class="h-6 items-center rounded flex gap-2 lg:h-8 px-1">
			<button
				class="h-full w-32 shrink-0 rounded text-left transition-colors"
				:class="expanded ? 'hover:bg-white' : 'hover:bg-gray-100'"
				@click="expanded = !expanded"
			>
				<FAIcon
					icon="chevron-down"
					class="mx-2 transition-transform duration-500 ease-out"
					:class="expanded && 'rotate-180'"
				/>{{ dayLabel }}
			</button>

			<div class="grid h-full w-full grid-flow-col gap-1">
				<div v-for="ts in timeSlots" :key="ts.time" class="relative">
					<div
						class="peer h-full border-2 border-gray-900/10"
						:class="timeSlotColor(ts.teamMembersAvailable)"
					></div>

					<div
						class="absolute bottom-full left-1/2 z-10 -translate-x-1/2 peer-hover:block hidden"
					>
						<div
							class="whitespace-nowrap rounded-md bg-gray-800 text-white p-2 text-center"
						>
							<div class="font-semibold text-sm">
								{{ dayLabel }} {{ ts.beginning }}-{{ ts.end }}
							</div>
							<div class="text-xs">
								{{ ts.teamMembersAvailable }}/{{ totalNumberOfTeamMembers }} team
								members
							</div>
						</div>
						<div
							class="absolute left-1/2 top-full h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-gray-800"
						></div>
					</div>
				</div>
			</div>
		</div>
		<BaseExpand :expanded="expanded">
			<div v-if="teamMembers" class="grid mt-1.5">
				<BaseDialog v-for="tm in teamMembers" :key="tm.name">
					<template #activator="{ openDialog }">
						<button
							type="button"
							class="flex h-7 text-left w-full items-center gap-2 rounded p-0.5 px-1 transition-colors hover:bg-white lg:h-9"
							@click="openDialog"
						>
							<p
								class="w-32 shrink-0 px-2 text-ellipsis overflow-hidden whitespace-nowrap"
							>
								{{ tm.name }}
							</p>

							<div class="grid h-full w-full grid-flow-col gap-1">
								<div v-for="(tmts, i) in tm.timeSlots" :key="i" class="relative">
									<div
										class="h-full peer border-2 border-gray-900/10"
										:class="tmts.available && 'bg-gray-500'"
									></div>
									<div
										class="absolute bottom-full left-1/2 z-10 -translate-x-1/2 peer-hover:block hidden"
									>
										<div
											class="whitespace-nowrap rounded-md bg-gray-800 text-white p-2 text-center"
										>
											<div class="font-semibold text-sm">
												{{ dayLabel }} {{ tmts.beginning }}-{{ tmts.end }}
											</div>
											<div class="text-xs">
												{{ tmts.available ? 'Available' : 'Unavailable' }}
											</div>
										</div>
										<div
											class="absolute left-1/2 top-full h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-gray-800"
										></div>
									</div>
								</div>
							</div>
						</button>
					</template>

					<StaffViewCard
						:key="tm.name"
						:name="tm.name"
						:associate-id="tm.associate_id"
						:availability-blocks="schedule.availabilityBlocks[tm.associate_id]"
					/>
				</BaseDialog>
			</div>

			<div
				v-else
				class="w-full bg-gray-200 rounded-md text-center font-semibold mt-2 p-1 text-gray-600"
			>
				No staff scheduled today
			</div>
		</BaseExpand>
	</div>
</template>
<script setup>
import { ref } from 'vue';
import BaseExpand from '@/components/ui/BaseExpand.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import StaffViewCard from '@/components/appointments/StaffViewCard.vue';

const props = defineProps({
	dayLabel: { type: String, required: true },
	timeSlots: { type: Array, required: true },
	teamMembers: { type: Array, required: false },
	schedule: { type: Object, required: true },
});

const expanded = ref(false);
const totalNumberOfTeamMembers = ref(props.teamMembers?.length ?? 0);

function timeSlotColor(n) {
	if (!n) {
		return 'bg-gray-100';
	}
	const percentCoverage = (n / totalNumberOfTeamMembers.value) * 100;
	if (percentCoverage < 1) {
		return 'bg-gray-100';
	}
	if (1 <= percentCoverage && percentCoverage < 50) {
		return 'bg-orange-100';
	}
	if (50 <= percentCoverage && percentCoverage < 100) {
		return 'bg-orange-300';
	}
	return 'bg-orange-500';
}
</script>
