<template>
	<div>
		<h1 class="text-4xl font-bold">Let's Meet!</h1>
		<p class="mb-4">
			Have you ever wanted to offer your clients a way to book an appointment with you or one
			of your team members directly from your website? Now you can!
		</p>
		<p class="mb-4">
			When you sign up for appointment scheduling in MySFDomain, all of your M1 and M2 domains
			will be able to offer your clients a way to connect with you that fits into your team's
			schedule.
		</p>
		<div class="rounded-full bg-gray-100 p-2 pl-4 gap-4 flex w-fit items-center mb-4">
			<span class="font-semibold">$29/month</span>
			<BaseButton color="primary" @click="purchaseDialog = true"
				>Enable Appointment Scheduling</BaseButton
			>
		</div>
		<h2>Key Benefits</h2>
		<div
			class="grid xl:grid-cols-2 gap-4 place-items-center p-8 rounded-3xl border-2 border-gray-300 bg-gray-100"
		>
			<ul>
				<li>
					<h3>💻 Online Appointment Scheduling</h3>
					<p>
						Customers visiting your domain can book an appointment online without ever
						having to call.
					</p>
				</li>
				<li>
					<h3>📆 Calendar Sync</h3>
					<p>
						When an appointment gets confirmed, MySFDomain automatically adds it to your
						Outlook calendar.
					</p>
				</li>
				<li>
					<h3>🕙 Auto Time Blocking</h3>
					<p>MySFDomain optimizes your schedule by eliminating double bookings.</p>
				</li>
				<li>
					<h3>🖌️ Customizable</h3>
					<p>
						Are you taking the day off? Is one of your team members out next week? Are
						you shifting your lunch break to earlier in the day? Are you switching to 4
						days a week instead of 5? We make it easy to customize your availability.
						You only get appointments that work with your and your team's schedule.
					</p>
				</li>
			</ul>
			<img :src="MXStaticImage" alt="" />
		</div>
		<h2>How It Works</h2>
		<div
			class="grid xl:grid-cols-2 gap-4 place-items-center p-8 rounded-3xl border-2 border-gray-300 bg-gray-100"
		>
			<ul>
				<li>
					<h3>🍬 Simple Integration</h3>
					<p>
						Adding an appointment scheduler to your domain is easier than stealing candy
						from a baby (trust us). Click the orange button above to get started.
					</p>
				</li>
				<li>
					<h3>👋🏻 Availability</h3>
					<p>
						Set up your weekly availability, including hours of operation and who is
						available during those hours. Get as flexible as you want: each day can have
						different hours and different staff availability.
					</p>
				</li>
				<li>
					<h3>♾️ Unlimited Appointments, Unlimited Domains</h3>
					<p>
						Once you’ve signed up for appointment scheduling, you can enable the feature
						on as many of your M1 and M2 domains as you’d like for no extra charge.
					</p>
				</li>
				<li>
					<h3>😌 Sit Back and Relax</h3>
					<p>
						Appointment scheduling is low maintenance. Replace the headache of managing
						your schedule with the joy of meeting your clients face-to-face.
					</p>
				</li>
			</ul>
			<img :src="MySFImage" alt="" />
		</div>
		<div
			class="w-full bg-gray-600 text-white border-2 border-gray-700 p-8 rounded-3xl text-center mt-8"
		>
			<h2 class="!mt-0">Get Appointment Scheduling!</h2>
			<p>You <i>know</i> you want to.</p>
			<div
				class="rounded-full bg-gray-700 p-2 pl-4 gap-4 flex w-fit items-center mt-4 mx-auto"
			>
				<span class="font-semibold">$29/month</span>
				<BaseButton color="primary" @click="purchaseDialog = true"
					>Get Appointment Scheduling!</BaseButton
				>
			</div>
		</div>
		<BaseDialog :value.sync="purchaseDialog" max-width="800">
			<template #header> Confirm Appointment Scheduling Purchase </template>
			<p class="dark:text-gray-300">Please confirm this billing change:</p>
			<table class="block px-4 py-3 text-black dark:text-gray-300">
				<tbody class="block">
					<td class="w-full">
						Monthly rate will
						<b>increase</b>
						by:
					</td>
					<td class="text-right">$29</td>
					<hr class="gray my-2" />
					<tr>
						<td class="w-full">Payment Due Today:</td>
						<td class="text-right">$29</td>
					</tr>
					<hr class="gray my-2" />
					<tr>
						<td class="w-full">
							Total new monthly payment starting on
							{{ nextMonthlyPaymentDate }}:
						</td>
						<td class="text-right">${{ newRate }}</td>
					</tr>
				</tbody>
			</table>
			<template #actions="{ closeDialog }">
				<BaseButton color="darkGray" text @click="closeDialog()">Cancel</BaseButton>
				<BaseButton
					@click="
						signUpForAppointmentScheduling();
						closeDialog();
						router.push(`/agents/${agent.associate_id}/appointments/calendar`);
					"
					>Confirm Purchase</BaseButton
				>
			</template>
		</BaseDialog>
	</div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import MXStaticImage from '@/assets/images/greg-mx-static.png';
import MySFImage from '@/assets/images/greg-mysf-ui.png';

import useAppointmentStore from '@/stores/agent/agent-appointments';
import useAgentStore from '@/stores/agent/agent';

const { signUpForAppointmentScheduling } = useAppointmentStore();
const { agent } = useAgentStore();
const router = useRouter();

const purchaseDialog = ref(false);
const currentRate = ref(54); //TODO: get this from the API
const newRate = computed(() => {
	return currentRate.value + 29;
});
const nextMonthlyPaymentDate = ref('09-01-2024'); //TODO: get from API
</script>
<style lang="scss" scoped>
h2 {
	font-size: 1.5rem;
	font-weight: bold;
	margin: 1rem 0 0.5rem 0;
}

ul {
	li:not(:last-child) {
		margin-bottom: 1rem;
	}

	li {
		h3 {
			font-size: 1.25rem;
			font-weight: bold;
			margin-bottom: 0.5rem;
		}
		p {
			font-size: 1rem;
		}
	}
}

img {
	padding: 0 4rem;
	object-fit: contain;
}
</style>
