<template>
	<BaseSnackbar :display="saveStatus !== 'hide'">
		<div class="mb-5 items-start rounded-lg border-2 border-gray-300 bg-white p-4">
			<div class="flex place-content-center">
				<div class="my-auto shrink-0">
					<FAIcon
						v-if="saveStatus === 'saving'"
						spin
						class="h-8 w-8 text-blue-400"
						icon="circle-notch"
					/>
					<div v-if="saveStatus === 'saved'">
						<FAIcon
							class="h-10 w-10 rounded-full border-4 border-blue-400 bg-blue-50 p-2 text-3xl text-blue-600 shadow-md"
							:icon="rndIcon('party')"
						/>
					</div>
				</div>
				<div class="ml-3 pt-0.5">
					<div v-if="saveStatus === 'saving'">
						<p class="text-xl font-medium text-gray-900">
							Saving your changes, one moment...
						</p>
						<div class="mt-1 text-base text-gray-500">
							<ul class="list-inside list-disc">
								<transition-group name="fade">
									<li v-for="b in bullets" :key="b">
										<span>{{ b }}</span>
									</li>
								</transition-group>
							</ul>
						</div>
					</div>
					<div v-if="saveStatus === 'saved'">
						<p class="text-xl font-medium text-gray-900">
							Nice Work -- Your domain is updated!
						</p>
						<div class="mt-2 text-lg text-gray-700">
							<div v-if="saveDomain">
								Your changes are in production, and can be viewed at
								<a
									class="truncate text-base leading-none"
									target="_blank"
									:href="`https://${saveDomain}${saveRoute}`"
								>
									<FAIcon icon="fa-solid fa-arrow-up-right-from-square" />
									https://{{ saveDomain }}{{ saveRoute }}
								</a>
							</div>
							<div v-else>
								Click the <b>Live View</b> button or use a web browser or a mobile
								device to see the changes.
							</div>
						</div>
					</div>
					<div v-if="saveStatus === 'error'">
						<p class="text-xl font-medium text-red-900">
							Those changes were not saved!
						</p>
						<div
							class="mx-2 my-3 flex justify-start space-x-3 rounded-xl border-2 border-x-red-300 border-y-transparent p-2"
						>
							<div class="mt-1">
								<FAIcon
									icon="skull"
									class="h-4 w-4 rounded-full border-2 border-red-300 bg-red-100 p-1 text-xl text-red-600 shadow-md"
								/>
							</div>

							<div class="flex flex-col items-start space-y-1 text-red-800">
								<div class="font-mono text-base font-bold">Error Details:</div>
								<div class="font-mono text-sm">{{ saveError.message }}</div>
							</div>
						</div>
						<div class="mt-1 text-base text-gray-600">
							Try waiting a few minutes and saving your changes again. If this error
							persists, please make a note of the
							<span class="text-red-600">Error Details</span> and contact us for
							support.
						</div>
					</div>
				</div>
			</div>

			<HelpPleaseNote v-if="saveStatus === 'saved'">
				Changes that are pending approval will not appear in the live view
				<span v-if="saveDomain">or on {{ saveDomain }}</span> until they have been approved.
			</HelpPleaseNote>
		</div>
	</BaseSnackbar>
</template>

<script setup>
import { ref, watch } from 'vue';
import { rndIcon } from '@/utils';
import { storeToRefs } from 'pinia';

import BaseSnackbar from '@/components/ui/BaseSnackbar';
import HelpPleaseNote from '@/components/MXEditor/help/HelpPleaseNote.vue';
import useEditorChangesStore from '@/stores/editorChanges';

const bullets = ref([]);
const editorChangesStore = useEditorChangesStore();
const { saveStatus, saveDomain, saveRoute, saveError } = storeToRefs(editorChangesStore);

const bulletList = [
	'Storing your updates',
	'Compiling the new configuration',
	'Rendering your fresh, updated domain',
];

watch(
	() => saveStatus,
	status => {
		bullets.value = [];
		if (status.value === 'saving') {
			for (let i = 0; i < bulletList.length; i++) {
				const b = bulletList[i];
				setTimeout(
					() => {
						bullets.value.push(b);
					},
					i * 1500 + 2500
				);
			}
		}
	},
	{ immediate: true, deep: true }
);
</script>

<style>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 1.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
