<template>
	<div
		class="flex justify-between rounded-3xl border-2 border-gray-300 bg-gray-100 p-4 shadow dark:border-gray-900 dark:bg-gray-700 dark:text-gray-100 dark:shadow-gray-700 lg:grid-cols-[min-content,_1fr,_max-content] lg:gap-4"
	>
		<div class="ml-1.5 mt-1.5 flex">
			<div class="relative inline-block">
				<div
					:class="[
						`text-${productType.badgeColor}-800`,
						`border-${productType.badgeColor}-800`,
						`bg-${productType.badgeColor}-100 dark:bg-${productType.badgeColor}-800`,
					]"
					class="inline-flex h-14 w-14 items-center justify-center rounded-md border-2 p-1 text-2xl font-semibold"
				>
					<span>{{ productType.initials }}</span>
				</div>

				<FAIcon
					class="absolute left-0 top-0 block h-3 w-3 -translate-x-2 -translate-y-2 rounded-full bg-green-50 p-1 text-xs text-green-600 ring-2 ring-green-600"
					icon="lock"
				/>
			</div>
			<h4 class="ml-2 flex w-1/2 flex-col md:w-auto">
				<span class="!m-0 w-full truncate pb-1 text-xl font-medium leading-none">
					{{ domain.domain_name }} - Coming Soon!
				</span>
				<span class="!m-0 truncate p-0 leading-none">
					<span class="mr-1 text-xs uppercase tracking-widest">
						{{ lastEvent.type }}:
					</span>
					<span class="fmr-1 text-xs font-bold uppercase tracking-widest">{{
						lastEvent.date_formatted ?? 'Never'
					}}</span>
				</span>
				<span class="mb-2 pt-2 w-full text-md font-medium leading-none">
					Your Domain is currently being purchased, and will be ready shortly!
				</span>
			</h4>
		</div>
	</div>
</template>
<script setup>
import { computed } from 'vue';
import { parseISO, format } from 'date-fns';

import { productTypes } from '@/constants';

const props = defineProps({
	domain: { type: Object, required: true },
});

const lastEvent = computed(() => {
	return {
		date_formatted: props.domain.last_service_event_date
			? format(parseISO(props.domain.last_service_event_date), 'LLL do yyyy')
			: null,
		type: props.domain.last_service_event_type,
	};
});

const productType = computed(() => productTypes[props.domain.product_type]);
</script>
<style>
/*
	TW dynamic styles
	text-red-800 border-red-800 bg-red-100
	text-orange-800 border-orange-800 bg-orange-100
	text-green-800 border-green-800 bg-green-100
	text-blue-800 border-blue-800 bg-blue-100
	text-indigo-800 border-indigo-800 bg-indigo-100
*/
</style>
