<template>
	<div class="flex max-w-96 mx-auto flex-col items-center overflow-clip !rounded-xl bg-white">
		<div
			v-if="!noTitle"
			class="inline-flex w-full flex-col items-start bg-gray-700 p-4 text-lg font-bold text-white"
		>
			<button
				class="flex flex-col rounded outline-none ring-white/70 transition focus:ring"
				:disabled="mode !== 'years'"
				aria-label="Return to calendar view"
				aria-live="assertive"
				@click.stop="mode = 'calendar'"
			>
				{{ dateLabel }}
			</button>
		</div>
		<div class="w-full" :class="{ 'h-[328px]': !noTitle }">
			<div v-show="mode === 'calendar'">
				<div class="flex items-center justify-between p-4">
					<button
						class="inline-flex h-8 w-8 items-center justify-center rounded-full outline-none ring-gray-500 transition hover:bg-gray-200 focus:ring disabled:invisible"
						type="button"
						:disabled="disabledPrevMonth"
						aria-label="Previous month"
						@click.stop="prevMonth()"
					>
						<FAIcon icon="chevron-left" />
					</button>
					<BaseButton
						color="dark-gray"
						aria-label="Quick select date"
						@click.stop="mode = 'years'"
					>
						{{ calendar.longMonthLabel }}, {{ calendar.year }}
					</BaseButton>
					<button
						class="inline-flex h-8 w-8 items-center justify-center rounded-full outline-none ring-gray-500 transition hover:bg-gray-200 focus:ring disabled:invisible"
						type="button"
						:disabled="disabledNextMonth"
						aria-label="Next month"
						@click.stop="nextMonth()"
					>
						<FAIcon icon="chevron-right" />
					</button>
				</div>
				<div class="flex flex-col px-4 items-center" :class="{ 'pb-4': noTitle }">
					<div class="grid grid-cols-[repeat(7,40px)]">
						<div
							v-for="dayLabel in dayLabels"
							:key="dayLabel"
							class="text-center text-sm font-bold text-gray-700"
						>
							{{ dayLabel }}
						</div>
					</div>
					<div
						class="grid"
						:class="
							noTitle ? 'grid-rows-[repeat(5,40px)]' : 'grid-rows-[repeat(6,40px)]'
						"
					>
						<div
							v-for="(week, weekIndex) in calendar.weeks"
							:key="weekIndex"
							class="grid grid-cols-[repeat(7,40px)] items-center justify-items-center"
						>
							<button
								v-for="(day, i) in week"
								:key="`${calendar.year}_${calendar.longMonthLabel}_${day}_${i}`"
								type="button"
								class="inline-flex items-center justify-center rounded-full text-sm font-bold outline-none ring-orange-500/50 focus:z-10 focus:ring active:ring-0"
								:class="dateClasses(day)"
								:disabled="isDisabled(day)"
								@click.prevent="selectDate(day)"
							>
								{{ day ? day.getDate() : '' }}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				v-show="mode === 'years'"
				class="flex w-full flex-col gap-2 overflow-y-scroll p-4"
				:class="noTitle ? 'h-[304px]' : 'h-full'"
			>
				<BaseButton
					v-for="year in yearOptions"
					:key="year"
					text
					color="gray"
					class="py-2"
					@click.stop="selectYear(year)"
				>
					{{ year }}
				</BaseButton>
			</div>
			<div v-show="mode === 'months'" class="flex h-full w-full flex-col">
				<div class="inline-flex items-center justify-between p-4">
					<button
						class="inline-flex h-8 w-8 items-center justify-center rounded-full outline-none ring-gray-500 transition hover:bg-gray-200 focus:ring disabled:invisible"
						:disabled="disabledPrevYear"
						aria-label="Previous year"
						@click.stop="prevYear()"
					>
						<FAIcon icon="chevron-left" />
					</button>
					<BaseButton color="dark-gray" @click="mode = 'years'">{{
						calendar.year
					}}</BaseButton>
					<button
						class="inline-flex h-8 w-8 items-center justify-center rounded-full outline-none ring-gray-500 transition hover:bg-gray-200 focus:ring disabled:invisible"
						:disabled="disabledNextYear"
						aria-label="Next year"
						@click.stop="nextYear()"
					>
						<FAIcon icon="chevron-right" />
					</button>
				</div>
				<div class="grid h-full w-full auto-rows-max grid-cols-3 gap-2 p-4">
					<BaseButton
						v-for="month in monthOptions"
						:key="month"
						text
						color="gray"
						class="!p-2"
						@click.stop="selectMonth(month)"
					>
						{{ shortMonthLabels[month] }}
					</BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, computed } from 'vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import {
	startOfDay,
	endOfDay,
	isDate,
	isToday,
	subMonths,
	subYears,
	addMonths,
	addYears,
	addDays,
	isAfter,
	isBefore,
	startOfMonth,
	endOfMonth,
	setYear,
	setMonth,
	isSameMonth,
	isSameYear,
	eachYearOfInterval,
	isWithinInterval,
	isSameDay,
} from 'date-fns';

const emit = defineEmits(['update:value']);
const props = defineProps({
	min: { type: [String, Date], required: true },
	max: { type: [String, Date], required: true },
	range: { type: Boolean, default: false },
	noTitle: { type: Boolean, default: false },
	showCurrent: { type: Boolean, default: true },
});

const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const shortMonthLabels = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

const minDate = new Date(props.min);
const maxDate = new Date(props.max);

const mode = ref('calendar');
const displayedMonth = ref(startOfMonth(maxDate));
const selectedDates = ref([]);

const yearOptions = eachYearOfInterval({
	start: startOfDay(minDate),
	end: endOfDay(maxDate),
})
	.map(date => date.getFullYear())
	.sort((a, b) => b - a);
const monthOptions = computed(() => {
	if (calendar.value.year === minDate.getFullYear()) {
		return Array.from({ length: 12 }, (_, i) => i).slice(minDate.getMonth());
	}
	if (calendar.value.year === maxDate.getFullYear()) {
		return Array.from({ length: 12 }, (_, i) => i).slice(0, maxDate.getMonth() + 1);
	}
	return Array.from({ length: 12 }, (_, i) => i);
});

const disabledPrevMonth = computed(() => {
	return isSameMonth(displayedMonth.value, minDate);
});
const disabledNextMonth = computed(() => {
	return isSameMonth(displayedMonth.value, maxDate);
});
const disabledPrevYear = computed(() => {
	return isSameYear(displayedMonth.value, minDate);
});
const disabledNextYear = computed(() => {
	return isSameYear(displayedMonth.value, maxDate);
});

const dateLabel = computed(() => {
	if (!props.range) {
		return dateLabelify(selectedDates.value[0]) || 'Select a date';
	}
	if (selectedDates.value.length === 1) {
		return `${dateLabelify(selectedDates.value[0])} -`;
	}
	if (selectedDates.value.length === 2) {
		return `${dateLabelify(selectedDates.value[0])} - ${dateLabelify(selectedDates.value[1])}`;
	}
	return 'Select a date range';
});

const calendar = computed(() => {
	const firstDayOfMonth = displayedMonth.value;
	const lastDayOfMonth = endOfMonth(displayedMonth.value);

	const weeks = [];

	let week = [];
	for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
		week.push(null);
	}

	for (let i = 0; i < lastDayOfMonth.getDate(); i++) {
		if (week.length === 7) {
			weeks.push(week);
			week = [];
		}
		const day = addDays(firstDayOfMonth, i);
		week.push(day);
	}

	if (week.length > 0) {
		weeks.push(week);
	}

	return {
		year: displayedMonth.value.getFullYear(),
		longMonthLabel: firstDayOfMonth.toLocaleString('en-US', { month: 'long' }),
		weeks,
	};
});

function dateLabelify(date) {
	return date.toLocaleString('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});
}

function isDisabled(date) {
	return isBefore(date, minDate) || isAfter(date, maxDate) || !isDate(date);
}
function isSelected(date) {
	return selectedDates.value.some(selectedDate => isSameDay(selectedDate, date));
}
function isStart(date) {
	return isSameDay(selectedDates.value[0], date);
}
function isEnd(date) {
	return isSameDay(selectedDates.value[1], date);
}
function betweenSelectedDates(date) {
	if (selectedDates.value.length === 2) {
		return isWithinInterval(date, {
			start: selectedDates.value[0],
			end: selectedDates.value[1],
		});
	}
	return false;
}
function dateClasses(date) {
	if ((isAfter(date, maxDate) || isBefore(date, minDate)) && !!date) {
		return 'bg-gray-200 text-gray-500 h-8 w-8';
	}
	if (selectedDates.value.length == 2 && isStart(date) && isEnd(date)) {
		return 'bg-orange-500 text-black w-full h-full';
	}
	if (selectedDates.value.length == 2 && isStart(date)) {
		return 'bg-orange-500 text-black rounded-r-none w-full h-full';
	}
	if (selectedDates.value.length == 2 && isEnd(date)) {
		return 'bg-orange-500 text-black rounded-l-none w-full h-full';
	}
	if (isSelected(date)) {
		return 'bg-orange-500 text-black w-full h-full';
	}
	if (betweenSelectedDates(date)) {
		return 'bg-orange-100 text-orange-700 rounded-none w-full h-full';
	}
	if (isToday(date)) {
		return 'border-2 border-solid border-gray-900 w-full h-full hover:border-orange-700 hover:bg-orange-100 hover:text-orange-700';
	}
	if (isDate(date)) {
		return 'text-gray-900 bg-transparent h-full w-full hover:bg-orange-100 hover:text-orange-700 disabled:invisible';
	}
}

function nextMonth() {
	displayedMonth.value = addMonths(displayedMonth.value, 1);
}
function prevMonth() {
	displayedMonth.value = subMonths(displayedMonth.value, 1);
}
function nextYear() {
	displayedMonth.value = addYears(displayedMonth.value, 1);
}
function prevYear() {
	displayedMonth.value = subYears(displayedMonth.value, 1);
}

function selectYear(year) {
	displayedMonth.value = setYear(displayedMonth.value, year);
	mode.value = 'months';
}
function selectMonth(month) {
	displayedMonth.value = setMonth(displayedMonth.value, month);
	mode.value = 'calendar';
}
function selectDate(date) {
	if (!props.range) {
		selectedDates.value[0] = [date];
		emit('update:value', date);
		return;
	}
	if (selectedDates.value.length === 0 || selectedDates.value.length === 2) {
		selectedDates.value.splice(0, 2, date);
	} else if (selectedDates.value.length === 1) {
		if (isBefore(date, selectedDates.value[0])) {
			selectedDates.value = [date, ...selectedDates.value];
		} else {
			selectedDates.value = [...selectedDates.value, date];
		}
	}
	emit('update:value', selectedDates.value);
}
</script>
