<template>
	<p v-if="loading" class="flex w-full flex-col text-center text-2xl text-orange-500">
		Loading domain info
		<ProgressBar />
	</p>
	<div v-else class="flex flex-col gap-2">
		<div class="grid grid-cols-1 gap-2 2xl:grid-cols-3">
			<template v-for="(val, key) in productTypes">
				<div v-if="key !== domain.product_type && key !== 'old_parked'" :key="key">
					<DomainTypeChange
						:title="`${getAction(key).label} to ${val.titleLong}?`"
						:disabled="isDisabled(key)"
						:action-color="getAction(key).color"
						:action-icon="getAction(key).icon"
						:action-text="`${getAction(key).label}`"
						:price="val.price"
						:options="{
							service: domain.domain_name,
							original_product_type: domain.product_type,
							future_product_type: key,
							email_count: emailCount,
						}"
						:emails="emailCount"
						@confirm="
							domainStore.setProductType(agent.associate_id, domain.domain_name, key)
						"
					>
						<template #title
							>Confirm {{ getAction(key).label }} to {{ val.titleLong }}</template
						>
						<template #description>
							<p>{{ val.description }}</p>
							<b v-if="disableForNoTarget(key)"
								>You cannot downgrade your only Secure Domain</b
							>
							<b v-if="disabledForIntern(key)">
								{{ redirectOnlyForAgentInternText }}
							</b>
							<M2DiscountList v-if="key === 'm2'" />
						</template>
					</DomainTypeChange>
				</div>
			</template>
		</div>
		<div class="flex flex-col gap-2 xl:flex-row">
			<div
				v-if="domain.product_type === 'm1' || domain.product_type === 'm2'"
				class="flex w-full flex-col gap-1 !rounded-md bg-white p-4 pt-3 xl:w-1/4"
			>
				<h5 class="text-xl font-bold">Language:</h5>
				<BaseSelect
					id="languageChoice"
					:value.sync="languageChoice"
					:items="languageOptions"
					label="Language"
					dark
					outlined
					pill
				/>
				<BaseButton
					color="primary"
					:disabled="!changed"
					class="mt-auto self-end"
					@click="onSaveOptions"
				>
					Save Changes
				</BaseButton>
			</div>
			<DomainTypeChange
				title="Cancel this domain?"
				action-text="Cancel Domain"
				action-color="error"
				action-icon="circle-x"
				:options="{
					service: domain.domain_name,
					original_product_type: domain.product_type,
					amount: 0,
					email_count: emailCount,
				}"
				class="w-full xl:w-3/4 xl:grow"
				@confirm="onCancel"
			>
				<template #description>
					<template v-if="cancelling">
						<ProgressBar class="w-full" :height="1" />
						<BaseAlert type="warn" class="mt-2">
							Canceling <code>{{ domain.domain_name }}</code
							>. Please do not leave this page.
						</BaseAlert>
					</template>
					<template v-else>
						<p class="pt-2">
							Cancelling a domain removes it from your account completely, any email
							redirects for this domain will disabled, and allows it to be purchased
							by others. Have you considered downgrading this domain to a Secure
							Parked domain instead?
						</p>
						<BaseAlert type="error">
							WARNING: If you cancel your domain and another individual or business
							purchases it, you will be unable to get it back in the future unless and
							until they release it.
						</BaseAlert>
					</template>
				</template>
			</DomainTypeChange>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { storeToRefs } from 'pinia';

import { productTypes, redirectOnlyForAgentInternText } from '@/constants';
import { useApi } from '@/composables/useApi';

import useAgentStore from '@/stores/agent/agent';
import useDomainStore from '@/stores/agent/agent-domains';
import useEditorStore from '@/stores/editor';

import BaseAlert from '@/components/ui/BaseAlert.vue';
import BaseSelect from '@/components/ui/BaseSelect';
import BaseButton from '@/components/ui/BaseButton.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

import DomainTypeChange from '@/components/domains/DomainTypeChange.vue';
import M2DiscountList from '@/components/domains/purchase/M2DiscountList.vue';

const props = defineProps({
	domain: { type: Object, required: true },
	emailCount: { type: Number, required: true },
	parkedTargetCount: { type: Number, required: true },
});

const languages = {
	english: { text: 'English', value: 'en' },
	spanish: { text: 'Spanish', value: 'es' },
};

const agentStore = useAgentStore();
const domainStore = useDomainStore();
const editorStore = useEditorStore();

const { agent } = storeToRefs(agentStore);

const languageChoice = ref(null);
const saving = ref(false);
const cancelling = ref(false);

const isIntern = computed(() => agent.value?.is_agent_intern ?? false);

const languageOptions = computed(() => {
	return props.domain.allowed_languages.map(item => languages[item]).filter(item => !!item);
});

const loading = computed(() => saving.value);

const changed = computed(() => languageChoice.value !== props.domain.options.language);

onMounted(async () => {
	languageChoice.value = props.domain.options.language;
	await nextTick();
	await editorStore.updateConfig(props.domain.domain_name);
	await domainStore.ensureDomains();
});

async function onSaveOptions() {
	saving.value = true;
	await useApi(`domains/${props.domain.domain_name}/`, {
		v3Agent: true,
		message: 'There was an issue saving your domain name! Please try again later.',
	}).put({
		product_type: props.domain.product_type,
		language: languageChoice.value,
	});

	await domainStore.getDomains();
	saving.value = false;
}

function disabledForIntern(productType) {
	return isIntern.value && ['m1', 'm2'].includes(productType);
}

function disableForNoTarget(productType) {
	return (
		props.parkedTargetCount <= 1 &&
		['parked', 'old_parked'].includes(productType) &&
		['m1', 'm2', 'sf_microsite'].includes(props.domain.product_type)
	);
}

function isDisabled(productType) {
	return disabledForIntern(productType) || disableForNoTarget(productType);
}

async function onCancel() {
	cancelling.value = true;
	await domainStore.cancelDomain({ domainName: props.domain.domain_name });
	cancelling.value = false;
}

function getAction(productType) {
	const p = Object.keys(productTypes);
	const diff = p.indexOf(props.domain.product_type) - p.indexOf(productType);
	if (diff > 0) {
		return { label: 'Upgrade', color: 'success', icon: 'arrow-up' };
	}
	if (diff === 0) {
		return null;
	}
	if (diff < 0) {
		return { label: 'Downgrade', color: 'warning', icon: 'arrow-down' };
	}
}
</script>
