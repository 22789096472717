<template>
	<section
		class="max-h-full w-full overflow-scroll rounded bg-white"
		:class="{ 'bg-gray-200': dragover }"
	>
		<header class="sticky top-0 z-10 bg-white px-4 py-2 drop-shadow">
			<h4
				class="flex items-start justify-between text-lg font-bold !leading-tight lg:text-xl"
			>
				Upload {{ title }}
				<button
					v-if="src"
					type="button"
					class="rounded-full border-2 border-solid border-white bg-white p-0 px-2 text-orange-600 transition-colors hover:bg-orange-100 focus:ring focus:ring-orange-500"
					aria-label="Cancel upload & abandon edits."
					@click.prevent="resetImage"
				>
					<FAIcon icon="xmark" />
				</button>
			</h4>
			<p class="mb-0 text-sm !leading-tight lg:text-base">
				Image should be at least {{ width }}px in width, {{ height }}px in height, and no
				larger than 3MB.
			</p>
			<ProgressBar v-if="status === 'saving'" />
		</header>
		<div class="flex flex-col items-stretch justify-center px-4 py-2">
			<!-- Current or pending DCO image-->
			<ImageOverride
				v-if="
					(currentImage || pendingImage || confirmedBlobURL) &&
					!['editing'].includes(status)
				"
				:alt-text="`${title} ${imageIndex} current custom uploaded image`"
				:currently-active-image-u-r-l="currentImage"
				:pending-image-override-u-r-l="pendingImage"
				:in-flight-image-u-r-l="confirmedBlobURL"
				:rounding-class="circleMask ? 'rounded-full' : 'rounded'"
				v-bind="{ height, width }"
			/>

			<!-- Cropper component-->
			<Cropper
				v-if="src && status !== 'confirmed'"
				id="cropper"
				ref="cropper"
				default-boundaries="fill"
				role="presentation"
				check-orientation
				image-restriction="stencil"
				:transitions="false"
				:resize-image="{
					touch: true,
					wheel: { ratio: 0.1 },
					adjustStencil: false,
				}"
				:src="src"
				:min-width="width"
				:min-height="height"
				:stencil-size="calculateStencilSize"
				:stencil-props="stencilProps"
				:stencil-component="circleMask ? 'circle-stencil' : 'rectangle-stencil'"
				class="max-w-screen-3xl mx-auto mt-1 max-h-[50vh] w-full shrink-0 overflow-hidden"
				@change="handleCropperChange"
			/>

			<!-- file input with image size validation-->
			<ValidationProvider
				:id="`imageProvider${imageIndex}`"
				ref="imageProvider"
				v-slot="{ errors }"
				class="group flex flex-col"
				:name="`${name} - uploaded image`"
				mode="eager"
				tag="div"
				:rules="{
					required,
					dimensions: {
						minWidth: width,
						minHeight: height,
						croppedHeight: croppedHeight,
						croppedWidth: croppedWidth,
						tooSkinny,
						tooShort,
					},
					image: 'image/*',
					size: { limit: 3000, currentSize: blobSize },
				}"
			>
				<label
					ref="dropZoneRef"
					class="group mt-1 flex h-24 w-full cursor-pointer flex-col items-center justify-center rounded-md border bg-gray-300 p-3 text-center text-lg font-bold"
					:for="`imageInput-${name.replace(/\s/g, '_')}`"
					:class="[{ 'border-4 border-orange-400 bg-gray-400': isOverDropZone }]"
				>
					<input
						:id="`imageInput-${name.replace(/\s/g, '_')}`"
						ref="imageInput"
						type="file"
						class="peer h-0 w-0 overflow-hidden"
						accept="image/*"
						aria-label="Upload new image"
						@change="handleFileSelect"
						@keydown.up.stop.prevent="move('up')"
						@keydown.down.stop.prevent="move('down')"
						@keydown.left.stop.prevent="move('left')"
						@keydown.right.stop.prevent="move('right')"
						@keydown.x.prevent="zoom('in')"
						@keydown.z.prevent="zoom('out')"
						@drop.prevent="handleDrop($event)"
						@dragover.prevent="dragover = true"
						@dragenter.prevent="dragover = true"
						@dragleave.prevent="dragover = false"
						@click:clear="resetImage"
					/>
					<span
						class="flex items-center rounded-md px-2 transition-all ease-in-out group-hover:scale-105 group-active:scale-95 peer-focus:ring peer-focus:ring-blue-700"
					>
						<FAIcon class="mr-2 text-4xl text-orange md:text-5xl" icon="cloud-upload" />
						Click or drag &amp; drop to upload a{{ currentImage ? ' different' : 'n' }}
						image
					</span>
				</label>
				<BaseAlert v-if="errors?.length > 0" type="error" class="col-span-12" role="alert">
					{{ errors[0] }}
				</BaseAlert>
			</ValidationProvider>

			<BaseAlert
				v-if="blobSize"
				type="info"
				dense
				tabindex="-1"
				aria-live="polite"
				class="mt-1"
			>
				<div>
					<p class="mb-0 text-sm">
						File size: {{ (blobSize / 1024 / 1024).toPrecision(2) }} MB
					</p>
					<p class="mb-0 text-sm">
						Cropped Image Dimensions: {{ croppedWidth }}px X {{ croppedHeight }}px
					</p>
				</div>
			</BaseAlert>

			<!-- status update-->
			<BaseAlert v-if="alert" :type="alert.type" class="mt-2">
				{{ alert.text }}
			</BaseAlert>

			<!--image control buttons-->
			<div
				v-if="src && status !== 'confirmed'"
				class="mt-2 grid max-w-prose grid-cols-4 gap-4 self-center 2xl:grid-cols-8"
			>
				<ImageControl
					label="replace"
					class-list="row-start-1 "
					icon="file-image"
					text="Replace image"
					@click="triggerFileUpload"
				/>

				<ImageControl
					v-if="rotateButtons"
					label="rotate"
					icon="rotate-right"
					text="Rotate Clockwise"
					class-list="2xl:row-start-1"
					@click="rotate('right')"
				/>

				<ImageControl
					v-if="zoomButtons && !(tooSkinny || tooShort)"
					label="zoomIn"
					icon="magnifying-glass-plus"
					text="Zoom In"
					class-list="2xl:row-start-1"
					@click="zoom('in')"
				/>
				<ImageControl
					v-if="zoomButtons && !(tooSkinny || tooShort)"
					label="zoomOut"
					icon="magnifying-glass-minus"
					text="Zoom Out"
					class-list="2xl:row-start-1"
					@click="zoom('out')"
				/>
				<ImageControl
					v-if="!tooSkinny"
					label="shiftLeft"
					class-list="row-start-2 2xl:row-start-1"
					icon="arrow-left"
					:disabled="isAtEdge.left"
					text="Shift Left"
					@click="move('left')"
				/>
				<ImageControl
					v-if="!tooSkinny"
					label="shiftRight"
					class-list="row-start-2 2xl:row-start-1"
					icon="arrow-right"
					:disabled="isAtEdge.right"
					text="Shift Right"
					@click="move('right')"
				/>

				<ImageControl
					v-if="!tooShort"
					label="shiftUp"
					class-list="row-start-2 2xl:row-start-1"
					icon="arrow-up"
					:disabled="isAtEdge.top"
					text="Shift Up"
					@click="move('up')"
				/>

				<ImageControl
					v-if="!tooShort"
					label="shiftDown"
					class-list="row-start-2 2xl:row-start-1"
					icon="arrow-down"
					:disabled="isAtEdge.bottom"
					text="Shift Down"
					@click="move('down')"
				/>
			</div>

			<!--keyboard controls-->
			<div v-if="status !== 'confirmed'" class="mb-8 mt-4">
				<h4 class="text-xl font-bold text-black">Keyboard Controls</h4>
				<p
					:id="`keyboardInstructions${imageIndex}`"
					class="!mt-0 mb-4"
					aria-live="assertive"
				>
					<template v-if="src">
						After uploading an image, focus this button and use the 'Z' key to zoom out,
						the 'X' key to zoom in, and the arrow keys to reposition the image.
					</template>
					<template v-else>
						Drag and drop your photo here, or click to select one to upload.
					</template>
				</p>
				<BaseButton
					v-if="src"
					x-small
					color="primary"
					aria-controls="cropper"
					:aria-describedby="`keyboardInstructions${imageIndex}`"
					@click="$event.target.focus()"
					@keydown.up.prevent="move('up')"
					@keydown.down.prevent="move('down')"
					@keydown.left.prevent="move('left')"
					@keydown.right.prevent="move('right')"
					@keydown.x.prevent="zoom('in')"
					@keydown.z.prevent="zoom('out')"
				>
					focus me for keyboard controls
				</BaseButton>
			</div>

			<!--image adjustment instructions-->
			<section v-if="src && status !== 'confirmed'">
				<h5 class="text-xl font-bold">Adjust the image if needed:</h5>

				<ul class="list-inside list-disc text-sm text-black">
					<li>Use the buttons below the image to <b>Rotate</b>, <b>Zoom</b>, etc.</li>
				</ul>
			</section>
		</div>

		<!--suggestions dialog-->
		<BaseDialog v-if="suggestions" :value.sync="showSuggestions" max-width="600">
			<template #header>
				<h4>Suggested Photo Ideas</h4>
			</template>
			<ImageSuggestions :width="width" :height="height" :override-type="overrideType" />
		</BaseDialog>

		<!--guidelines & upload dialog-->
		<BaseDialog :value.sync="showGuidelines" max-width="600" dense>
			<ImageGuidelines
				:override-type="overrideType"
				:height="height"
				:width="width"
				:name="name"
			/>
			<template #actions="{ closeDialog }">
				<BaseButton
					color="gray"
					:disabled="savingChanges || ['saving', 'saved'].includes(status)"
					@click="closeDialog()"
				>
					Cancel
					<template #append><FAIcon icon="rotate-left" /></template>
				</BaseButton>
				<BaseButton
					color="primary"
					:disabled="savingChanges || ['saving', 'saved'].includes(status)"
					@click="confirmChanges().then(() => closeDialog())"
				>
					Agree
					<template #append><FAIcon icon="check" /></template>
				</BaseButton>
			</template>
		</BaseDialog>

		<footer
			v-if="suggestions || src"
			class="sticky bottom-0 flex flex-wrap items-center justify-end gap-2 bg-white p-2 drop-shadow"
		>
			<BaseButton v-if="suggestions" dense outline @click="showSuggestions = true">
				View Suggestions
			</BaseButton>
			<template v-if="src">
				<template v-if="status !== 'confirmed'">
					<BaseButton
						dense
						color="gray"
						aria-label="Cancel upload & abandon edits."
						@click="resetImage"
					>
						Reset
					</BaseButton>
					<BaseButton
						dense
						color="primary"
						:disabled="confirmDisabled"
						@click="showAgreementDialog"
					>
						Confirm edits
					</BaseButton>
				</template>
				<BaseButton v-else dense color="gray" @click="status = 'editing'">
					Back to editing
				</BaseButton>
			</template>
		</footer>
	</section>
</template>

<script setup>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import { computed, nextTick, onMounted, onUnmounted, reactive, ref } from 'vue';

import { getMimeType } from '@/utils';
import ImageSuggestions from '@/components/MXEditor/images/ImageSuggestions';
import useAlertStore from '@/stores/alerts';
import useEditorStore from '@/stores/editor';
import { storeToRefs } from 'pinia';
import { useDropZone } from '@vueuse/core';

import BaseAlert from '@/components/ui/BaseAlert';

import ImageControl from '@/components/MXEditor/images/ImageControl';
import ImageGuidelines from '@/components/MXEditor/images/ImageGuidelines';
import ImageOverride from '@/components/MXEditor/images/ImageOverride';
import ProgressBar from '@/components/ui/ProgressBar';
import BaseButton from '@/components/ui/BaseButton';
import BaseDialog from '@/components/ui/BaseDialog';

const emit = defineEmits(['update:blob-to-upload', 'banner-uploaded']);

const props = defineProps({
	// Cropperjs options
	viewMode: { type: Number, default: 0 },
	dragMode: { type: String, default: 'crop' },
	autoCropArea: { type: Number, default: 0.8 },

	// ImageEditor options
	circleMask: { type: Boolean, default: false },
	uploading: { type: Boolean, default: true },
	resetButton: { type: Boolean, default: true },
	rotateButtons: { type: Boolean, default: true },
	zoomButtons: { type: Boolean, default: true },
	sliderColor: { type: String, default: null },
	title: { type: String, default: 'Custom Image' },
	imageIndex: { type: Number, default: 0 },

	containerHeight: { type: Number, default: 350 },
	containerWidth: { type: Number, default: 350 },
	height: { type: Number, default: 350 },
	width: { type: Number, default: 350 },

	resizeHeight: { type: Number, default: null },
	resizeWidth: { type: Number, default: null },

	overrideType: {
		/* override type (NOT THE KEY) */
		type: String,
		required: true,
		validator: prop => {
			const options = [
				'agent_avatar',
				'staff_avatar',
				'office_banner',
				'jobs_team_images',
				'mission_tab_images',
				'team_tab_images',
				'more_info_image',
			];
			const valid = options.includes(prop);
			if (!valid) {
				console.error(
					`${prop} is not a valid image type. Valid types include ${options.join(' ')}`
				);
			}
			return valid;
		},
	},
	currentImage: { /*default image from e.g. microsite*/ type: String, default: null },
	pendingImage: { /*a pending image override*/ type: String, default: null },
	blobToUpload: {
		/*Blob to be uploaded to GCS when override is saved*/ type: Blob,
		default: null,
	},
	name: { type: String, default: 'Image' },

	required: { type: Boolean, default: false },
	suggestions: { type: Boolean, default: false },

	condensed: { type: Boolean, default: false },

	triggerOnMount: { type: Boolean, default: false },
});

// refs used in template
const imageProvider = ref(null);
const imageInput = ref(null);

const cropper = ref(null);

const editorStore = useEditorStore();
const { savingChanges } = storeToRefs(editorStore);

function calculateStencilSize({ boundaries }) {
	return {
		height: boundaries.height - 75,
		width: boundaries.width - 75,
	};
}

const stencilProps = computed(() => ({
	aspectRatio: props.width / props.height,
	minContainerHeight: props.height,
	minContainerWidth: props.width,

	// example taken from: https://advanced-cropper.github.io/vue-advanced-cropper/guides/advanced-recipes.html#fixed-stencil
	handlers: {},
	resizable: false,
	movable: false,
	scalable: false,
}));
const confirmDisabled = computed(() => {
	return !blob.value || status.value === 'saving' || imageProvider?.value.flags?.invalid;
});
const status = ref('init');
const dragover = ref(false);
const croppedHeight = ref(null);
const croppedWidth = ref(null);
const src = ref(null);
const file = ref(undefined);
const blob = ref(null);
const blobSize = ref(0);
const showSuggestions = ref(false);
const showGuidelines = ref(false);

const dropZoneRef = ref(null);
const { isOverDropZone } = useDropZone(dropZoneRef, handleDrop);

const confirmedBlobURL = computed(() => {
	if (blob.value) {
		return URL.createObjectURL(blob.value);
	}
	if (props.blobToUpload) {
		return URL.createObjectURL(props.blobToUpload);
	}
	return null;
});

const tooSkinny = computed(() => croppedWidth.value < props.width);
const tooShort = computed(() => croppedHeight.value < props.height);

async function confirmChanges() {
	status.value = 'confirming';
	showGuidelines.value = false;
	emit('banner-uploaded', false);
	if (blob.value && imageProvider?.value.flags?.valid) {
		try {
			emit('update:blob-to-upload', blob.value);

			status.value = 'confirmed';
		} catch (error) {
			console.error(error);
			// status.value = 'error';
		}
	} else {
		console.error({ blob: blob.value });
	}
}

const alert = computed(() => {
	switch (status.value) {
		case 'saving':
			return {
				type: 'info',
				text: 'Uploading image...',
			};
		case 'saved':
			return {
				type: 'success',
				text: 'Image uploaded successfully.',
			};
		case 'error':
			return {
				type: 'error',
				text: 'Upload failed! Please try again.',
			};
		case 'init':
		case 'editing':
		default:
			return undefined;
	}
});

const isAtEdge = reactive({
	top: true,
	right: true,
	bottom: true,
	left: true,
});

async function handleCropperChange({ coordinates = {}, visibleArea = {}, canvas }) {
	status.value = 'editing';

	croppedHeight.value = coordinates.height;
	croppedWidth.value = coordinates.width;

	isAtEdge.top = coordinates.top === 0;
	isAtEdge.right = Math.round(visibleArea?.left) + coordinates.width === props.width;
	isAtEdge.bottom = coordinates.top + coordinates.height === props.height;
	isAtEdge.left = Math.round(visibleArea?.left) === 0 || coordinates.left === 0;

	await canvas?.toBlob(
		async newBlob => {
			blob.value = newBlob;
			blobSize.value = newBlob?.size || 0;

			await nextTick();
			await imageProvider.value?.validate();
		},
		getMimeType(file.value, file.value?.type)
	);
}

async function loadImage(files = []) {
	status.value = 'editing';
	const newFile = files[0];

	// Ensure that you have a file before attempting to read it
	if (newFile) {
		file.value = newFile;
		// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (src.value) {
			URL.revokeObjectURL(src.value);
		}

		// 2. Create the blob link to the file to optimize performance:
		try {
			// Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
			src.value = URL.createObjectURL(newFile);
			imageInput.value?.focus();
			await nextTick();
		} catch (error) {
			console.error(error);
		}
		const reader = new FileReader();
		// Define a callback function to run, when FileReader finishes its job
		reader.onload = async () => {
			await imageProvider.value?.validate(files);
		};
		// Start the reader job - read file as a data url (base64 format)
		await reader.readAsArrayBuffer(newFile);
	}
}

function move(direction) {
	const { coordinates } = cropper.value ?? {};
	switch (direction) {
		case 'left':
			cropper.value.move(-coordinates.width / 10);
			break;
		case 'right':
			cropper.value.move(coordinates.width / 10);
			break;
		case 'up':
			cropper.value.move(0, -coordinates.height / 10);
			break;
		case 'down':
			cropper.value.move(0, coordinates.height / 10);
			break;
		default:
			break;
	}
}
function zoom(direction) {
	switch (direction) {
		case 'in':
			cropper.value.zoom(1.1);
			break;
		case 'out':
			cropper.value.zoom(0.9);
			break;
		default:
			break;
	}
}

function rotate() {
	cropper.value.rotate(90);
}

function triggerFileUpload() {
	imageInput.value?.click();
}
async function handleFileSelect(event) {
	await loadImage(event.target.files);
	emit('banner-uploaded', true);
}

async function handleDrop(files = []) {
	dragover.value = false;
	if (files.length > 1) {
		const alertStore = useAlertStore();

		alertStore.addAlert({
			message: 'Only one photo can be uploaded at a time.',
			type: 'warning',
		});
	} else {
		// massage drop event into file input type expected by the validator
		await loadImage(files);
	}
}

async function showAgreementDialog() {
	//call validation to check image size
	const res = await imageProvider.value.validate();

	const { valid } = res;
	// if valid, show guidelines

	if (valid) {
		showGuidelines.value = true;
	}
}

function resetImage() {
	file.value = null;

	src.value = null;
	blob.value = null;
	blobSize.value = 0;
	status.value = 'init';
	imageProvider.value.reset();
	emit('banner-uploaded', false);
	emit('update:blob-to-upload', null);
}

onMounted(() => {
	if (props.triggerOnMount) {
		triggerFileUpload();
	}
});

onUnmounted(() => {
	// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
	if (src.value) {
		URL.revokeObjectURL(src.value);
	}
});

defineExpose({
	loadImage,
	confirmChanges,
	handleCropperChange,
	triggerFileUpload,
	handleFileSelect,
	resetImage,
	imageProvider,
	confirmedBlobURL,
	imageInput,
	blob,
	status,
	src,
});
</script>

<style module lang="scss">
.drag {
	&:after {
		// todo: check if this is even used anymore
		content: '';
		@apply absolute -bottom-2 -left-2 -right-2 -top-2;
		@apply bg-gray-500 opacity-50;
	}
}
</style>
