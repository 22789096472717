export const linkGeneratorFAQs = [
	{
		path: 'link Generator',
		label: 'What is a Link Generator?',
		content: `<p>
								Agents and their digital advertising vendors are highly encouraged
								to use campaign IDs for their M1 domain and/or Quote Landing page
								URLs in order to track different digital advertising campaigns and
								tactics. If campaign IDs are not set up and attached to the URL
								properly, detailed campaign reporting cannot be provided.
							</p>

							<p>
								There are two options available for you to use to generate a
								campaign ID (aka tracking code) for your M1 domain or domain/Quote
								Landing page URL.
							</p>
							<ol>
								<li>
									Use the Link Generator tool above, use the dropdown options to
									the populate fields. The URL with campaign ID code will be
									displayed at the bottom of the Link Generator tool.
								</li>
								<li>
									Or use the State Farm Agent Quote Landing Page (AQLP) Set Up
									Guide:
									<a
										href="http://sfnet.opr.statefarm.org/agency/marketing/digital_marketing/quote_landing_page/AgentQuoteLandingPageSetUp.shtml"
										>Link</a
									>
								</li>
							</ol>`,
	},
	{
		path: 'Usage of Links',
		label: 'What do I do with the link I have generated?',
		content: `Use this URL to link to your online ad/marketing campaign(s), provide it
					to your marketing vendor or save the URL somewhere until you are ready
					to use it.`,
	},
	{
		path: 'Campaign ID data',
		label: 'Where do I see my Campaign ID data?',
		content: `<p>
								Regardless of which page you are using, M1 Homepage or a Quote
								Landing Page, daily metrics will be available here, in the "Stats" tab of your Campaigns page.
								This data will help you track how well your digital marketing
								efforts are performing.
							</p>
							<p>
								If you are using the Quote Landing Page (domain/quote) URL with a
								campaign ID and you have campaign data for the month, reports will
								be automatically emailed (to the agent only) by the seventh of each
								month by ASR. If there is no AQLP data/activity for the month, you
								will not receive an email from ASR.
							</p>
							<ul>
								<li>
									The email's subject will be: Agent Quote Landing Page Report for
									Month Year.
								</li>
								<li>
									Note: The report from ASR will not include Agent Tagged Media
									(ATM) AQLP reporting data, you will need to visit SF Navigator
									for all data related to ATM.
								</li>
							</ul>
							<p>
								For any questions regarding your AQLP report, please contact Agency
								Sales Resource (ASR) by completing the ASR Online Contact Form or
								for urgent issues, call
								<a href="tel:+18778892294">877-889-2294</a>.
							</p>`,
	},
];

export const availableLanguageOptions = {
	english: { text: 'English', value: 'en' },
	spanish: { text: 'Spanish', value: 'es' },
};

export const trafficSources = [
	{ value: 'BD', text: 'Bing/Yahoo Ads Desktop' },
	{ value: 'BM', text: 'Bing/Yahoo Ads Mobile' },
	{ value: 'DD', text: 'Desktop Display' },
	{ value: 'ET', text: 'Email and Email Template' },
	{ value: 'FD', text: 'Facebook Desktop' },
	{ value: 'FM', text: 'Facebook Mobile' },
	{ value: 'FN', text: 'Facebook Newsfeed' },
	{ value: 'FP', text: 'Facebook Promoted Post' },
	{ value: 'GD', text: 'Google Adwords Desktops' },
	{ value: 'GM', text: 'Google Adwords Mobile' },
	{ value: 'HU', text: 'Hulu' },
	{ value: 'IH', text: 'iHeart Radio' },
	{ value: 'IS', text: 'Imprinted Stationary' },
	{ value: 'EM', text: 'In-Book Email Marketing' },
	{ value: 'IY', text: 'Internet Yellow Pages Ads' },
	{ value: 'KB', text: 'KellyBlueBook.com' },
	{ value: 'LD', text: 'LinkedIn Desktop' },
	{ value: 'LM', text: 'LinkedIn Mobile' },
	{ value: 'MD', text: 'Mobile Display' },
	{ value: 'PD', text: 'Pandora' },
	{ value: 'SP', text: 'Spotify' },
	{ value: 'FM', text: 'X (formerly Twitter) Desktop' },
	{ value: 'TM', text: 'X (formerly Twitter) Mobile' },
	{ value: 'VE', text: 'Video Enhancement' },
	{ value: 'VP', text: 'Video Pre-Roll' },
	{ value: 'WT', text: 'Weather.com' },
	{ value: 'YD', text: 'Yelp Desktop' },
	{ value: 'Ym', text: 'Yelp Mobile' },
	{ value: 'U1', text: 'Other/Undefined 1' },
	{ value: 'U2', text: 'Other/Undefined 2' },
	{ value: 'U3', text: 'Other/Undefined 3' },
	{ value: 'U4', text: 'Other/Undefined 4' },
	{ value: 'U5', text: 'Other/Undefined 5' },
	{ value: 'U6', text: 'Other/Undefined 6' },
	{ value: 'U7', text: 'Other/Undefined 7' },
	{ value: 'U8', text: 'Other/Undefined 8' },
	{ value: 'U9', text: 'Other/Undefined 9' },
];

export const allRouteOptions = [
	// QLP pages, both Mirus and SF
	{ value: 'quote', text: 'Homepage QLP', key: 'mxquotelanding_general' },
	{ value: 'quote/auto', text: 'Auto Quote QLP', key: 'mxquotelanding_auto' },
	{ value: 'quote/condo', text: 'Condo Quote QLP', key: 'mxquotelanding_condo' },
	{
		value: 'quote/homeowners',
		text: 'Homeowners Quote QLP',
		key: 'mxquotelanding_homeowners',
	},
	{ value: 'quote/hospital', text: 'Hospital Quote QLP', key: 'mxquotelanding_hospital' },
	{ value: 'quote/life', text: 'Life Quote QLP', key: 'mxquotelanding_life' },
	{ value: 'quote/medicare', text: 'Medicare Quote QLP', key: 'mxquotelanding_medicare' },
	{ value: 'quote/pet', text: 'Pet Quote QLP', key: 'mxquotelanding_pet' },

	{ value: 'quote/renters', text: 'Renters Quote QLP', key: 'mxquotelanding_renters' },
	{ value: 'auto', text: 'Auto QLP', key: 'mxquotelanding_auto_root' },
	{ value: 'condo', text: 'Condo QLP', key: 'mxquotelanding_condo_root' },
	{ value: 'homeowners', text: 'Homeowners QLP', key: 'mxquotelanding_homeowners_root' },
	{ value: 'hospital', text: 'Hospital QLP', key: 'mxquotelanding_hospital_root' },
	{ value: 'life', text: 'Life QLP', key: 'mxquotelanding_life_root' },
	{ value: 'medicare', text: 'Medicare QLP', key: 'mxquotelanding_medicare_root' },
	{ value: 'pet', text: 'Pet QLP', key: 'mxquotelanding_pet_root' },
	{ value: 'renters', text: 'Renters QLP', key: 'mxquotelanding_renters_root' },
	{ value: 'quote', text: 'Homepage SF QLP', key: 'sfquotelanding_general' },
	{ value: 'quote/auto', text: 'Auto Quote SF QLP', key: 'sfquotelanding_auto' },
	{ value: 'quote/condo', text: 'Condo Quote SF QLP', key: 'sfquotelanding_condo' },
	{
		value: 'quote/homeowners',
		text: 'Homeowners Quote SF QLP',
		key: 'sfquotelanding_homeowners',
	},
	{ value: 'quote/hospital', text: 'Hospital Quote SF QLP', key: 'sfquotelanding_hospital' },
	{ value: 'quote/life', text: 'Life Quote SF QLP', key: 'sfquotelanding_life' },
	{ value: 'quote/medicare', text: 'Medicare Quote SF QLP', key: 'sfquotelanding_medicare' },
	{ value: 'quote/pet', text: 'Pet Quote SF QLP', key: 'sfquotelanding_pet' },
	{ value: 'quote/renters', text: 'Renters Quote SF QLP', key: 'sfquotelanding_renters' },
	{ value: 'auto', text: 'Auto SF QLP', key: 'sfquotelanding_auto_root' },
	{ value: 'condo', text: 'Condo SF QLP', key: 'sfquotelanding_condo_root' },
	{ value: 'homeowners', text: 'Homeowners SF QLP', key: 'sfquotelanding_homeowners_root' },
	{ value: 'hospital', text: 'Hospital SF QLP', key: 'sfquotelanding_hospital_root' },
	{ value: 'life', text: 'Life SF QLP', key: 'sfquotelanding_life_root' },
	{ value: 'medicare', text: 'Medicare SF QLP', key: 'sfquotelanding_medicare_root' },
	{ value: 'pet', text: 'Pet SF QLP', key: 'sfquotelanding_pet_root' },
	{ value: 'renters', text: 'Renters SF QLP', key: 'sfquotelanding_renters_root' },
	{ value: 'root', text: 'Homepage', key: 'root' },
	//
	// Non QLP
	//
	{ value: 'insurance/auto', text: 'Auto Insurance page', key: 'mxlineofbusiness_auto' },
	{ value: 'insurance/boat', text: 'Boat Insurance page', key: 'mxlineofbusiness_boat' },
	{
		value: 'insurance/business',
		text: 'Business Insurance page',
		key: 'mxlineofbusiness_business',
	},
	{ value: 'insurance/condo', text: 'Condo Insurance page', key: 'mxlineofbusiness_condo' },
	{
		value: 'insurance/homeowners',
		text: 'Homeowners Insurance page',
		key: 'mxlineofbusiness_homeowners',
	},
	{ value: 'insurance', text: 'Insurance page', key: 'mxlineofbusiness_general' },
	{ value: 'insurance/life', text: 'Life Insurance page', key: 'mxlineofbusiness_life' },
	{
		value: 'insurance/motorcycle',
		text: 'Motorcycle Insurance page',
		key: 'mxlineofbusiness_motorcycle',
	},
	{ value: 'insurance/pet', text: 'Pet Insurance page', key: 'mxlineofbusiness_pet' },
	{
		value: 'insurance/renters',
		text: 'Renters Insurance page',
		key: 'mxlineofbusiness_renters',
	},
	{
		value: 'insurance/recreational-vehicles',
		text: 'Rec Vehicles Insurance page',
		key: 'mxlineofbusiness_recreational-vehicles',
	},
	{ value: 'office2', text: 'Locations-Office 2', key: 'mxoffice2' },
	{ value: 'reviews', text: 'Reviews', key: 'mxreviews' },
	{ value: 'jobs', text: 'Jobs', key: 'mxjobslisting' },
];
