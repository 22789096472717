<template>
	<validation-observer ref="domainValidator" v-slot="{ invalid, handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(onSearch)">
			<fieldset
				:disabled="searching"
				class="flex flex-wrap items-center gap-2 bg-white rounded-md p-4"
			>
				<div class="text-2xl leading-none text-gray-400">www.</div>
				<div class="w-80">
					<validation-provider
						v-slot="{ errors }"
						:rules="{
							required: true,
							properlyFormedDomain: true,
							sldRestrictions: skipRestrictions
								? false
								: { list: agent.sld_restriction_list },
						}"
						class="w-full"
						name="Domain Name"
					>
						<TextFieldInput
							id="domain-name"
							ref="sldField"
							:value.sync="sld"
							label="Type a domain name"
							placeholder="Type a domain name"
							@keydown.f1.prevent="focusTld"
						>
							<template v-if="sld && errors[0]" #message>
								<span>{{ errors[0] }}</span>
							</template>
						</TextFieldInput>
					</validation-provider>
				</div>

				<validation-provider
					v-slot="{ errors }"
					:rules="{
						required: true,
						tldInList: { list: tlds.map(({ value }) => value) },
					}"
					name="TLD"
					slim
				>
					<BaseSelect
						id="tld-field"
						ref="tldField"
						:disabled="!sld"
						:items="tlds"
						:value.sync="tld"
						class="min-w-[8ch] max-w-[12ch]"
						label="TLD"
						outlined
						show-label
					>
						<template v-if="tld && errors[0]" #message>
							<span>{{ errors[0] }}</span>
						</template>
					</BaseSelect>
				</validation-provider>

				<MirusOnlyBorder dense inline>
					<CheckboxInput
						:checked.sync="skipRestrictions"
						:disabled="searching"
						class="px-2"
						name="skip-restrictions"
					>
						Skip domain name restrictions
					</CheckboxInput>
				</MirusOnlyBorder>
				<BaseButton
					:disabled="invalid || searching"
					:loading="searching"
					color="primary"
					rounded="md"
					type="submit"
				>
					Search
				</BaseButton>
			</fieldset>
		</form>
	</validation-observer>
</template>

<script setup>
import { computed, ref, onMounted, nextTick, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { onKeyStroke } from '@vueuse/core';
import { extend } from 'vee-validate';

import useAgentStore from '@/stores/agent/agent';

import BaseSelect from '@/components/ui/BaseSelect.vue';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import CheckboxInput from '@/components/ui/CheckboxInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

import MirusOnlyBorder from '@/components/common/MirusOnlyBorder.vue';

const emit = defineEmits('search');
defineProps({
	searching: { type: Boolean, required: true },
});

const tests = [
	{
		test: /^https?:?/gi,
		message: 'Do not include "http://" or "https://"',
	},
	{
		test: /^www\.?/gi,
		message: 'Do not include "www."',
	},
	{
		test: /\..*?$/g,
		message: `Do not include a trailing period or tld. Please select the tld (.com, .biz, etc.) using the dropdown to the right.`,
	},
	{
		test: /[^a-zA-Z\-.]/g,
		message: 'Use only letters and hyphens',
	},
];
extend('properlyFormedDomain', {
	validate: value => {
		for (let i = 0; i < tests.length; i += 1) {
			const { test, message } = tests[i];
			if (value?.match(test)) {
				return message;
			}
		}

		if (value?.length < 2) {
			return `Domain name must be at least 2 characters.`;
		}
		if (value?.length > 32) {
			return `State Farm limits domain names to 32 characters.`;
		}
		return true;
	},
});
extend('tldInList', {
	params: ['list'],
	validate: (value, { list }) => {
		const tldString = new Intl.ListFormat('en', {
			style: 'long',
			type: 'disjunction',
		}).format(list.map(tld => `.${tld}`));
		return list.includes(value) ? true : `Tld must be one of the following: ${tldString}`;
	},
});
extend('sldRestrictions', {
	params: ['list'],
	validate: (value, { list }) => {
		for (let i = 0; i < list.length; i += 1) {
			const term = list[i];
			const m = new RegExp(term, 'ig');
			const match = value.match(m);

			if (match) {
				return `Using "${match}" in your domain name is not allowed by SF Corporate`;
			}
		}
		return true;
	},
});
const agentStore = useAgentStore();

const { agent } = storeToRefs(agentStore);

const skipRestrictions = ref(false);

const sldField = ref(null);
const tldField = ref(null);
const domainValidator = ref(null);
let { domainName, updateDomainName } = inject('domainName');

const approvedTldList = computed(() => agent.value?.tld_approved_list ?? []);

onKeyStroke(
	'.',
	event => {
		event.preventDefault();
		if (sld.value) {
			tldField.value?.focus();
		}
	},
	{ target: sldField }
);

const sld = computed({
	get() {
		return domainName.value?.split('.')?.[0];
	},
	async set(newSld = '') {
		// if a tld is pasted in to the sld field, update the domain name with the tld
		let [s, t] = newSld.split('.');
		updateDomainName(s ? `${s}.${approvedTldList.value.includes(t) ? t : 'com'}` : null);
	},
});
const tld = computed({
	get() {
		return domainName.value?.split('.')[1];
	},
	set(newTld = 'com') {
		updateDomainName(
			sld.value
				? `${sld.value}.${approvedTldList.value.includes(newTld) ? newTld : null}`
				: null
		);
	},
});

const tlds = computed(
	() => approvedTldList.value?.map(item => ({ text: `.${item}`, value: item })) ?? []
);

function focusTld() {
	tldField.value?.focus();
}

function onSearch() {
	emit('search');
}
async function setupFromRouteQuery() {
	await nextTick();
	if (!approvedTldList.value.includes(tld.value)) {
		tld.value = null;
	}
	const valid = await domainValidator.value?.validate();

	if (sld.value && tld.value && valid) {
		onSearch();
	} else {
		sldField.value?.focus();
	}
}
onMounted(setupFromRouteQuery);
</script>

<style>
.tld-select .v-select__selections input {
	display: none;
}
</style>
