<template>
	<ValidationProvider
		name="Pre-approved Banner Image"
		tag="div"
		class="grid h-full min-h-0 grid-rows-[auto,1fr]"
	>
		<header
			class="relative mb-2 flex w-full grow-0 items-stretch gap-1 rounded-lg bg-gray-200 p-1 dark:bg-gray-600 dark:text-gray-800 max-sm:flex-col md:gap-2 md:p-2 lg:mb-4 xl:gap-4 xl:p-4"
		>
			<div class="flex basis-1/2 flex-col rounded-md bg-white p-2 dark:bg-gray-500 xl:p-4">
				<h4 class="mb-2 text-xl font-bold leading-none">Search pre-approved banners</h4>
				<BaseAutocomplete
					id="image-search"
					ref="imageSearch"
					:value.sync="selectedTags"
					clearable
					hide-selected
					show-results-toggle
					close-on-content-click
					:aria-label="`${
						selectedTags.length > 0
							? `Searched for ${selectedTags} and found ${
									filteredImages.length
								} image${filteredImages.length === 1 ? '' : 's'}`
							: 'Type to search the tag list.'
					}`"
					:items="tagOptions"
					multiple
					label="Find images by descriptive tags"
					aria-describedby="imageSearchDesc"
					@change="expanded = {}"
					@click:clear="expanded = {}"
				>
					<template #selection="{ selection }">
						<BaseButton
							:aria-label="`remove search filter ${selection}`"
							@click="removeTag(selection)"
						>
							{{ capitalize(selection) }}
							<template #append>
								<FAIcon class="text-xl" icon="xmark" />
							</template>
						</BaseButton>
					</template>
				</BaseAutocomplete>
			</div>
			<div v-if="current" class="basis-1/2 rounded-md bg-white p-2 dark:bg-gray-500 xl:p-4">
				<h4 class="mb-2 text-xl font-bold leading-none">Current Banner</h4>
				<ProgressBar v-if="savingChanges" />
				<img
					v-else
					class="aspect-[6/1] max-h-[250px] rounded-sm object-cover"
					:src="current"
					alt="Current banner image"
				/>
			</div>
			<span id="imageSearchDesc" class="sr-only">
				When autocomplete results are available use up and down arrows to review and enter
				to select. Touch device users, explore by touch or with swipe gestures.
			</span>
			<span aria-live="assertive" class="sr-only">{{ ariaText }}</span>
		</header>

		<ProgressBar
			v-if="loading"
			:text="`Loading images: ${loadProgress}%`"
			:value="loadProgress"
		/>

		<ul
			v-else
			class="z-0 grid h-full min-h-0 grid-cols-1 gap-1 overflow-y-auto overflow-x-hidden rounded-lg bg-gray-200 p-1 dark:bg-gray-600 lg:grid-cols-2 xl:gap-2 xl:p-2 2xl:grid-cols-3 2xl:gap-4 2xl:p-4"
			:class="{ '!grid-cols-1': expanded.secure_url }"
		>
			<ImageCard
				v-if="expanded.secure_url"
				:key="`expanded_${expanded.secure_url}`"
				:selected-tags.sync="selectedTags"
				:current="current === expanded.secure_url"
				v-bind="expanded"
				expanded
				@update:selectedTags="expanded = {}"
				@cancel="expanded = {}"
				@selected="
					expanded = {};
					$emit('submit');
				"
			/>
			<ImageCard
				v-for="image in filteredImages"
				v-else
				:key="image.public_id"
				class="mx-auto rounded-md bg-white p-2 pb-0 dark:bg-gray-500"
				:selected-tags.sync="selectedTags"
				v-bind="image"
				:current="current === image.secure_url"
				:selected="selectedBanner === image.secure_url"
				@update:selectedTags="expanded = {}"
				@expand="setExpandedImage(image)"
			/>
		</ul>
	</ValidationProvider>
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';

import { capitalize } from '@/utils';

import { useApi } from '@/composables/useApi';

import useEditorChangesStore from '@/stores/editorChanges';
import useEditorStore from '@/stores/editor';

import ProgressBar from '@/components/ui/ProgressBar';
import BaseButton from '@/components/ui/BaseButton';
import BaseAutocomplete from '@/components/ui/BaseAutocomplete.vue';

import ImageCard from '@/components/MXEditor/images/ImageCard';

defineEmits(['submit', 'cancel']);
defineProps({
	selectedBanner: {
		type: [String, Boolean],
		default: false,
	},
	current: {
		type: [String, Boolean],
		default: false,
	},
});

const editorStore = useEditorStore();
const { office } = storeToRefs(editorStore);

const imageSearch = ref(null);
const loading = ref(true);
const loadProgress = ref(0);
const expanded = ref({});
const images = ref([]);

const editorChangesStore = useEditorChangesStore();
const { savingChanges } = storeToRefs(editorChangesStore);

const selectedTags = ref([]);
const tagOptions = computed(() => {
	if (!images.value.length > 0) {
		return ['Default', 'Map'];
	}
	return [...new Set(filteredImages.value.flatMap(o => o.tags).filter(t => t))].sort();
});
const DISPLAY_ORDER_TAG_PREFIX = 'DISPLAY-ORDER-';
const DEPRECATED_IMAGE_TAG = 'DEPRECATED';

const filteredImages = computed(() => {
	if (selectedTags.value?.length > 0) {
		return images.value
			.map(image => ({ ...image }))
			.filter(image => {
				return selectedTags.value.every(selectedTag =>
					image.tags?.find(tag => tag.localeCompare(selectedTag) === 0)
				);
			});
	} else {
		return images.value;
	}
});
const ariaText = computed(() => {
	return selectedTags.value?.length > 0
		? `Showing preapproved images, filtered by tag${
				selectedTags.value.length > 1 ? 's' : ''
			} ${selectedTags.value.join(', ')}`
		: `Showing all preapproved images`;
});
function setExpandedImage(selectedImage) {
	if (selectedImage.secure_url === expanded.value.secure_url) {
		expanded.value = {};
	} else if (selectedImage) {
		expanded.value = selectedImage;
	}
}

function extractDisplayOrderFromTags(tags) {
	for (const t of tags) {
		if (t.startsWith(DISPLAY_ORDER_TAG_PREFIX)) {
			// Return the number from the first tag that matches.
			return parseInt(t.replace(DISPLAY_ORDER_TAG_PREFIX, ''));
		}
	}
	// If no tag matches, the default is to shuffle this image to the back.
	return 10000;
}

function removeTag(tag) {
	selectedTags.value = selectedTags.value.filter(t => t !== tag);
}

onMounted(async () => {
	const fetcher = useApi(`api/v3/image/banners/`, {
		message: `There was a problem fetching available banner images.`,
	});

	watchEffect(() => {
		loadProgress.value = fetcher.progress;
	});

	const { data } = await fetcher.json();

	const finalList = [
		{
			public_id: 'default',
			secure_url: office.value?.map.images.header.retina,
			tags: ['default', 'map', `${DISPLAY_ORDER_TAG_PREFIX}0`], // always put this image in the front of the list
		},
		...data.value,
	]
		.map(({ tags, ...rest }) => {
			const upperTags = tags?.map(t => t.toUpperCase().trim());
			return {
				...rest,
				tags: upperTags.filter(t => t && !t.startsWith(DISPLAY_ORDER_TAG_PREFIX)),
				displayOrder: extractDisplayOrderFromTags(upperTags),
			};
		})
		.filter(({ tags }) => {
			for (const t of tags) {
				if (t === DEPRECATED_IMAGE_TAG) {
					return false;
				}
			}
			return true;
		});
	finalList.sort((image1, image2) => image1.displayOrder - image2.displayOrder);

	images.value = finalList;
	loading.value = false;
});
</script>
