<template>
	<div
		:class="open ? 'shadow' : ''"
		class="flex items-stretch overflow-hidden rounded bg-white dark:bg-gray-400 dark:text-gray-900"
	>
		<div class="mr-10 w-1/2 p-4">
			<h3 class="text-xl font-medium">What is an M2 - Marketing Site?</h3>

			<template v-if="open">
				<p>
					In partnership with Corporate, Mirus Research has designed and developed a new
					Marketing oriented site for agents that utilizes industry leading techniques to
					increase page rank performance. M2 sites provide your agency with a multi-page
					website that includes all the current M1 features plus so much more!
				</p>
				<ul class="mb-4 list-inside list-disc font-medium">
					<li>
						Ability to display more custom images of you, your office, and your team
						throughout the site
					</li>
					<li>
						Line of Business product pages with curated, professionally written content
						that's automatically updated each month; making the M2 sites more relevant
						to searchers while removing the agent burden of constantly writing updated
						product content
					</li>
					<li>
						Star-rating and reviews from Google that help inform shoppers by showcasing
						what your current customers are saying about you
					</li>
					<li>
						Jobs listing page that includes links for applicants to apply through your
						recruiting vendor, such as Careerplug
					</li>
					<li>
						Additional office page for agents who have a CSSO/Satellite/Third Office; a
						place where agents can uniquely showcase this location online
					</li>
					<li>
						Ability to direct all your online marketing efforts to any page of this
						multi-page website; link ads/campaigns directly to Line of Business pages,
						Additional Office page, Reviews page or Jobs page
					</li>
				</ul>
				<p>And don't forget, M2 includes all the important M1 features such as:</p>
				<ul class="mb-4 list-inside list-disc font-medium">
					<li>Easy to use, real-time Editor to showcase your agency, compliantly</li>
					<li>
						Customizable About Me, Our Team, and Mission sections to highlight what you
						want customers to know about you and your agency
					</li>
					<li>Custom homepage banner image</li>
					<li>Real-time social media feed</li>
					<li>Custom business hours controls</li>
					<li>
						Simple Insights articles and YouTube videos that display relevant content
					</li>
					<li>Ability to assign permissions to a team member to manage M2</li>
					<li>Daily click metrics are always available on mysfdomain</li>
				</ul>
				<p>It's time to let your agency stand out online with M2!</p>

				<p class="font-italic font-medium">
					Looking for examples? Here is a demo site that displays all the M2 features:
				</p>

				<ul class="mb-4 list-inside list-disc">
					<li>
						<a href="https://m2foryou.com" rel="noopener noreferrer" target="_blank">
							m2foryou.com
						</a>
					</li>
				</ul>

				<h4 class="text-xl font-medium">Pricing</h4>
				<p>The total monthly price for a M2 enabled domain is $54/month.</p>

				<h4 class="text-xl font-medium pt-2">Getting started with M2 is easy:</h4>
				<p>Above this description is a list of your secure domain(s).</p>

				<p v-if="isIntern">
					<b>
						{{ redirectOnlyForAgentInternText }}
					</b>
				</p>
				<p v-else>
					Click the Settings drop down next to the domain where you want to display M2
					content and then select the "Enable M2" button .
				</p>

				<p>Have questions or need help activating an M2 - Marketing Site?</p>
				<p>
					Contact our support staff by <a href="tel:18778283131">Phone</a> or
					<a href="mailto:support@mysfdomain.com">Email</a>
				</p>

				<h4 class="text-xl font-medium pt-2">Upcoming M1/M2 Training Sessions</h4>
				<p>
					Offered by State Farm every other month, from 12-1:15 CST.<br />Please visit
					your 'Workday' site for the next available session and to enroll for 'Agent
					Microsite and M1/M2-Agent Marketing Sites' training. Note: All digital knowledge
					session information is available through
					<a
						href="https://sfnet.opr.statefarm.org/agency/training/training_glance/knowledgeSession_Listing.shtml?sProd=Digital_Marketing"
						>Agency Training Calendar under Knowledge sessions for Digital Marketing.</a
					>
				</p>
			</template>
			<p v-else>New domain option available! Learn more about <b>M2 - Marketing Sites</b></p>

			<BaseButton :disabled="upgrading" class="mt-1" color="gray" @click="toggle">
				{{ open ? `Got It` : `Show More` }}
			</BaseButton>
		</div>
		<template v-if="open">
			<div class="flex w-1/2 flex-col items-stretch justify-stretch">
				<img :src="m2desktop" alt="" class="grow object-cover" />
				<img :src="m2mobile" alt="" class="grow object-cover" />
			</div>
		</template>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';

import { redirectOnlyForAgentInternText } from '@/constants';
import m2desktop from '@/assets/images/editor/m2/m2_desktop_mockup.webp';
import m2mobile from '@/assets/images/editor/m2/m2_mobile_mockup.webp';

import useAgentStore from '@/stores/agent/agent';

import BaseButton from '@/components/ui/BaseButton.vue';

defineProps({
	domains: Array,
});

const upgrading = ref(false);
const open = ref(true);

const agentStore = useAgentStore();
const { isIntern } = storeToRefs(agentStore);

function toggle() {
	open.value = !open.value;
}
</script>

<style scoped></style>
