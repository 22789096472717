<template>
	<ValidationObserver :ref="`staticObserver-${associate_id}`" v-slot="validation" slim>
		<fieldset
			class="mt-2 space-y-2 rounded border-2 border-solid border-gray-200 p-2 text-gray-600"
		>
			<BaseAlert v-if="validation.changed" dense type="warn" :icon="false">
				Unsaved Changes
			</BaseAlert>
			<BaseAlert v-if="pendingImageURL" dense type="success" :icon="false">
				Image Pending Approval
			</BaseAlert>

			<legend class="rounded border-2 border-solid border-gray-200 px-1 font-bold text-black">
				{{ name }}
			</legend>

			<div class="grid grid-cols-[min-content_1fr] gap-2 xl:gap-4">
				<BaseDialog
					:value.sync="uploading"
					max-width="1200"
					class="row-span-2"
					dense
					:persistent="unconfirmedImageChange"
					@close="cancelingUpload = unconfirmedImageChange"
				>
					<template #activator>
						<div class="flex flex-col items-center gap-3">
							<button
								type="button"
								outline
								:disabled="!eligible_for_display"
								:style="!eligible_for_display ? 'cursor: default' : ''"
								:aria-label="`Edit profile image for ${name}`"
								class="col-span-1 col-start-1 row-start-1 justify-self-center"
								@click="uploading = true"
							>
								<EditorAvatar
									:image-u-r-l="staffAvatarURL"
									v-bind="{ initials, name }"
								/>
							</button>
							<BaseButton
								v-if="eligible_for_display"
								class="col-span-1 col-start-1 row-start-2"
								dense
								outline
								color="primary"
								@click="uploading = true"
							>
								Edit&nbsp;<span class="d-sr-only">profile</span> image
							</BaseButton>
						</div>
					</template>
					<ImageEditor
						ref="imageEditor"
						:blob-to-upload.sync="imageBlob"
						:pending-image="pendingImageURL"
						:current-image="image"
						override-type="staff_avatar"
						:aspect-ratio="1"
						:height="200"
						:width="200"
						:circle-mask="true"
						:name="name"
						:title="`profile image for ${name}`"
						@update:blob-to-upload="
							value => handleChange({ key: 'blobToUpload', value })
						"
					/>
				</BaseDialog>
				<CancelDialog
					v-if="cancelingUpload"
					:open.sync="cancelingUpload"
					@confirm="cancelUpload"
				/>

				<div class="grow-1 col-span-1 col-start-2 shrink-0 text-xs">
					<span v-if="associate_id">{{ associate_id }}</span>
					<div v-if="title">{{ title }}</div>
					<div v-if="license_number">License #{{ license_number }}</div>
					<div v-if="!eligible_for_display">
						Not displaying on
						<a :href="agent.microsite_url" target="_blank" rel="noopener noreferrer"
							>SF microsite</a
						>
					</div>
				</div>

				<ValidationProvider
					v-if="eligible_for_display"
					ref="visibilityProvider"
					v-slot="{ validate }"
					:name="`${name} - visibility`"
					tag="div"
					class="col-span-1 shrink-0 grow-0 justify-self-end text-right"
				>
					<BaseToggle
						:value.sync="visibility"
						:aria-label="`visibility`"
						visible-label="Visible"
						@update:value="
							value => {
								validate(value);
								handleChange({ key: 'visibile', value });
							}
						"
					/>
				</ValidationProvider>
				<div v-if="eligible_for_display" class="col-span-full flex items-baseline gap-1">
					<p class="mb-0 font-bold leading-none">Description &nbsp;</p>
					<BaseDialog
						:value.sync="editing"
						:persistent="unconfirmedDescriptionChange"
						max-width="600"
						@close="cancelingEditing = unconfirmedDescriptionChange"
					>
						<template #activator>
							<BaseButton color="primary" outline dense @click="editing = true">
								Edit <span class="d-sr-only">description</span>
							</BaseButton>
						</template>
						<template #header>
							<h3 class="text-xl font-bold">Edit description for {{ name }}</h3>
						</template>

						<ComplianceChecker
							:key="associate_id"
							:value.sync="description"
							autofocus
							:required="false"
							:name="`${name} - description`"
							:placeholder="`A brief description of ${name}`"
						/>
						<template #actions>
							<BaseButton color="error" x-small @click="cancelEditing">
								{{ unconfirmedDescriptionChange ? 'Undo Edits' : 'Cancel' }}
							</BaseButton>
							<BaseButton
								color="primary"
								:disabled="
									description === staff_copy ||
									!validation.changed ||
									validation.pending ||
									validation.invalid
								"
								@click="finishEditing"
							>
								Confirm Edits
							</BaseButton>
						</template>
					</BaseDialog>
					<CancelDialog
						v-if="cancelingEditing"
						:open.sync="cancelingEditing"
						@confirm="cancelEditing"
					/>
				</div>

				<div class="col-span-full" v-html="description" />
			</div>

			<BaseAlert v-if="pendingImageURL && !savingChanges" outlined dense type="info">
				Your new photo has been successfully submitted. It may take 1-2 business days for
				review, and we'll e-mail the results. If your photo is approved it will go live
				immediately.
			</BaseAlert>
		</fieldset>
	</ValidationObserver>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router/composables';

import useEditorStore from '@/stores/editor';

import useTeam from '@/composables/useTeam';

import BaseAlert from '@/components/ui/BaseAlert';
import BaseToggle from '@/components/ui/BaseToggle';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseDialog from '@/components/ui/BaseDialog';

import CancelDialog from '@/components/common/CancelDialog';

import ComplianceChecker from '@/components/MXEditor/ComplianceChecker';
import EditorAvatar from '@/components/MXEditor/EditorAvatar';
import ImageEditor from '@/components/MXEditor/images/ImageEditor';

const router = useRouter();
const route = useRoute();
const props = defineProps({
	// eslint-disable-next-line vue/prop-name-casing
	associate_id: {
		type: String,
		default: '',
	},
	initials: {
		type: String,
		default: '',
	},
	blobToUpload: {
		type: Blob,
		default: undefined,
	},
	image: {
		type: String,
		default: null,
	},
	name: {
		type: String,
		default: '',
	},
	// eslint-disable-next-line vue/prop-name-casing
	license_number: {
		type: String,
		default: '',
	},
	title: {
		type: String,
		default: '',
	},
	// eslint-disable-next-line vue/prop-name-casing
	staff_copy: {
		type: String,
		default: '',
	},
	approvedImage: {
		type: String,
		default: null,
	},
	visible: {
		type: Boolean,
		default: false,
	},
	// eslint-disable-next-line vue/prop-name-casing
	eligible_for_display: {
		type: Boolean,
		default: false,
	},
});
const emit = defineEmits(['update:blobToUpload', 'update:visible', 'update:description']);

const imageEditor = ref(null);
const editorStore = useEditorStore();
const { savingChanges, agent } = storeToRefs(editorStore);

const visibility = ref(props.visible);
const description = ref(props.staff_copy);
const editing = ref(false);
const uploading = ref(false);
const imageBlob = ref(undefined);
const cancelingUpload = ref(false);
const cancelingEditing = ref(false);

const { pendingStaffAvatars, getCurrentMember } = useTeam();

const pendingImageURL = computed(() => pendingStaffAvatars.value?.[props.associate_id]?.value);

const staffAvatarURL = computed(() => {
	if (imageBlob.value) {
		return URL.createObjectURL(imageBlob.value);
	}
	if (pendingImageURL.value) {
		return pendingImageURL.value;
	}
	return getCurrentMember(props.associate_id).image;
});

const unconfirmedImageChange = computed(() => {
	return !!imageEditor.value?.blob && imageEditor.value?.blob !== imageBlob.value;
});

const unconfirmedDescriptionChange = computed(() => {
	return description.value !== props.staff_copy;
});

function handleChange({ key, value }) {
	if (key === 'blobToUpload' && value) {
		uploading.value = false;
	}
	emit(`update:${key}`, value);
}

function finishEditing() {
	handleChange({ key: 'description', value: description.value });
	if (route.query?.associate_id === props.associate_id) {
		router.push({ query: undefined });
	}
	editing.value = false;
}

function cancelUpload() {
	uploading.value = false;
	imageEditor.value.resetImage();
	imageEditor.value.$forceUpdate();
	cancelingUpload.value = false;
}

function cancelEditing() {
	description.value = props.staff_copy;

	emit(`update:description`, props.staff_copy);

	finishEditing();
	cancelingEditing.value = false;
}

onMounted(() => {
	editing.value = route.query?.associate_id === props.associate_id;
});
</script>

<style lang="scss" scoped>
.vis-switch {
	margin-top: 0;
	padding-top: 0;
}
.vis-switch > .v-label {
	white-space: nowrap;
}
</style>
