<template>
	<figure
		class="flex aspect-square grow-0 items-center justify-center overflow-hidden rounded-full border-2 border-solid"
		:class="[
			{ 'bg-orange-500': error || !loaded },
			color === 'white' ? 'border-white' : 'border-gray-500',
		]"
		:style="{ width: widthString, height: widthString }"
	>
		<FAIcon
			v-if="error || !loaded"
			:size="placeholderSize"
			icon="user"
			class="h-1/2 w-1/2 text-white"
		/>

		<img
			v-if="!error"
			:height="loaded && !error ? widthString : 0"
			:width="loaded && !error ? widthString : 0"
			v-bind="{ position }"
			:src="imageUrl"
			:alt="alt"
			@load="loaded = true"
			@error="error = true"
		/>
	</figure>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const props = defineProps({
	position: { type: String, default: 'top' },
	width: { type: [String, Number], default: 'auto' },
	alt: { type: String, default: '' },
	color: { type: String, default: 'gray' },
	placeholderSize: { type: String, default: '2xl' },
	// eslint-disable-next-line vue/prop-name-casing
	associate_id: { type: String, default: undefined },
	avatar: { type: Boolean, default: true },
	thumbnail: { type: Boolean, default: true },
});

const loaded = ref(false);
const error = ref(false);

const imageUrl = computed(() => {
	const first_letter = props.associate_id?.substring(0, 1) ?? '';
	const filename = `formalColor${props.thumbnail ? 'Thumb' : 'Full'}.jpg`;
	return `https://ac2.st8fm.com/associate-photos/${first_letter}/${props.associate_id}/${filename}`;
});

const widthString = computed(() => {
	switch (typeof props.width) {
		case 'number':
			return `${props.width}px`;
		case 'string':
		default:
			return isNaN(Number(props.width)) ? props.width : `${Number(props.width)}px`;
	}
});

watch(imageUrl, newImageUrl => {
	if (newImageUrl) {
		error.value = false;
		loaded.value = true;
	}
});
</script>

<style scoped></style>
