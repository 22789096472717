<template>
	<RedirectLayout :icon1="loading ? 'refresh' : 'envelope'" icon2="share" :loading="loading">
		<template #column1>
			<p class="mb-0 leading-none">
				{{ redirect_address }}
				<span class="block text-sm font-bold text-gray-500 dark:text-gray-200">
					Email Address
				</span>
			</p>
		</template>
		<template #column2>
			<p class="mb-0 leading-none">
				{{ destination_address || real_address }}
				<span class="block text-sm font-bold text-gray-500 dark:text-gray-200"
					>Redirects to</span
				>
			</p>
		</template>
		<template #actions>
			<div class="flex w-full items-center justify-end gap-2">
				<BaseButton
					color="gray"
					outline
					dense
					:disabled="testStatus === 'working' || disabled"
					@click="testEmail"
				>
					<FAIcon
						v-if="testStatus"
						:class="testIconColor"
						:spin="testStatus === 'working'"
						:icon="testIconName"
					/>
					Test{{ testStatus === 'working' ? 'ing' : '' }}
				</BaseButton>
				<BaseButton
					color="green"
					outline
					dense
					:disabled="testStatus === 'working' || disabled"
					@click="$emit('editEmail')"
				>
					Edit
				</BaseButton>
				<BaseButton
					color="red"
					outline
					dense
					:disabled="testStatus === 'working' || disabled"
					@click="$emit('deleteEmail')"
				>
					Delete
				</BaseButton>
			</div>
		</template>
	</RedirectLayout>
</template>

<script setup>
import RedirectLayout from '@/components/emails/RedirectLayout';
import { computed, ref } from 'vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import useEmailStore from '@/stores/agent/agent-emails';

const props = defineProps({
	disabled: { type: Boolean, default: false },
	loading: { type: Boolean, default: false },
	// eslint-disable-next-line vue/prop-name-casing
	destination_address: { type: String },
	// eslint-disable-next-line vue/prop-name-casing
	real_address: { type: String },
	// eslint-disable-next-line vue/prop-name-casing
	redirect_address: { type: String, required: true },
	// eslint-disable-next-line vue/prop-name-casing
	domain_name: { type: String, required: true },
});

const emailStore = useEmailStore();

const testStatus = ref(null);
const testIconName = computed(() => {
	if (testStatus.value === 'success') {
		return 'check';
	}
	if (testStatus.value === 'failure') {
		return 'exclamation-triangle';
	}
	return 'refresh';
});
const testIconColor = computed(() => {
	if (testStatus.value === 'success') {
		return 'text-green-500';
	}
	if (testStatus.value === 'failure') {
		return 'text-red-500';
	}
	return 'text-gray-800';
});

async function testEmail() {
	testStatus.value = 'working';
	try {
		const data = await emailStore.testEmail({
			domain_name: props.domain_name,
			destination: props.redirect_address,
		});
		testStatus.value = data?.value?.success ? 'success' : 'failure';
	} catch (error) {
		testStatus.value = 'failure';
		console.error(error);
	}
}
</script>

<style scoped module lang="scss"></style>
