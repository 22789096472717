<template>
	<section class="flex flex-wrap gap-4 rounded bg-white p-4">
		<fieldset
			class="flex w-full flex-wrap items-end gap-4 rounded-md border-2 border-gray-800 p-4 shadow-inner shadow-gray-900 dark:bg-gray-700"
		>
			<legend
				class="col-span-full rounded-lg bg-gray-300 px-1 py-2 font-bold capitalize shadow shadow-gray-500 dark:bg-gray-700 dark:shadow-gray-900"
			>
				UI config props
			</legend>
			<RadioGroup
				:options="roundingOptions"
				:value.sync="uiConfig.rounded"
				class="col-span-6 flex shrink flex-wrap justify-start gap-2 border-2 border-gray-400 bg-gray-300 p-1 dark:bg-gray-600"
				legend="Rounding"
			/>
			<BaseToggle
				v-for="setting in ['disabled', 'outline', 'text', 'dense']"
				:key="setting"
				:name="setting"
				:value.sync="uiConfig[setting]"
			>
				{{ setting.toLocaleUpperCase() }}
			</BaseToggle>
			<RadioGroup
				:options="backgroundColorOptions"
				:value.sync="backgroundColorClass"
				class="col-span-6 col-start-1 grid shrink grid-cols-4 items-stretch gap-2 border-2 border-gray-400 bg-gray-300 px-1 pb-3 dark:bg-gray-600 lg:grid-cols-6"
				legend="Background color"
			>
				<template #label="{ value }">
					<span
						class="flex items-center justify-center gap-1 rounded-xl border-2 border-solid border-gray-300 p-1 pr-2 font-bold"
					>
						<span
							:class="value"
							class="block h-8 w-8 rounded-lg border-2 border-solid"
						/>
						{{ value }}
					</span>
				</template>
			</RadioGroup>
		</fieldset>
		<PlaygroundSection
			v-bind="{
				title: 'Buttons',
				subtitle: 'Links styled as buttons should look identical to the same color button',
				backgroundColorClass,
			}"
		>
			<div
				v-for="color in COLOR_LIST"
				:key="`button-${color}`"
				class="items center flex flex-col items-center gap-2 rounded border-2 border-gray-500 p-2 dark:border-gray-800"
			>
				<h4 class="text-lg font-medium uppercase text-gray-600 dark:text-gray-800">
					{{ color }}
				</h4>
				<BaseButton :color="color" v-bind="{ ...uiConfig }"> as button </BaseButton>
				<BaseButton :color="color" href="https://mysfdomain.com" v-bind="{ ...uiConfig }">
					As link
				</BaseButton>
			</div>
		</PlaygroundSection>

		<PlaygroundSection class="" v-bind="{ title: 'Links', backgroundColorClass }">
			<AppLink
				v-for="color in COLOR_LIST"
				:key="`link-${color}`"
				:color="color"
				:to="{ name: 'index' }"
				v-bind="{ ...uiConfig }"
				class="uppercase"
			>
				link
			</AppLink>
		</PlaygroundSection>

		<PlaygroundSection v-bind="{ title: 'Alerts', backgroundColorClass }">
			<BaseAlert
				v-for="alert in ['info', 'warn', 'error', 'success']"
				:key="`alert-${alert}`"
				:type="alert"
				class="w-full shrink-0"
				v-bind="{ ...uiConfig }"
			>
				This is a(n) <b>{{ alert.toUpperCase() }}</b> BaseAlert
			</BaseAlert>
		</PlaygroundSection>
	</section>
</template>
<script setup>
import RadioGroup from '@/components/ui/RadioGroup.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import BaseToggle from '@/components/ui/BaseToggle.vue';
import AppLink from '@/components/ui/AppLink.vue';
import { reactive, ref } from 'vue';
import PlaygroundSection from '@/components/ui/PlaygroundSection.vue';

const COLOR_LIST = ['orange', 'gray', 'darkGray', 'white', 'blue', 'green', 'red', 'yellow'];

const roundingOptions = [
	{ value: 'top' },
	{ value: 'right' },
	{ value: 'bottom' },
	{ value: 'left' },

	{ value: 'xs' },
	{ value: 'sm' },
	{ value: 'md' },
	{ value: 'lg' },
	{ value: 'xl' },

	{ value: 'full' },
	{ value: true },
	{ value: false },
];

const backgroundColorOptions = [
	{ value: 'bg-transparent' },
	{ value: 'bg-red-500' },
	{ value: 'bg-orange-100' },
	{ value: 'bg-orange-300' },
	{ value: 'bg-orange-500' },
	{ value: 'bg-orange-700' },
	{ value: 'bg-orange-900' },
	{ value: 'bg-yellow-500' },
	{ value: 'bg-green-500' },
	{ value: 'bg-blue-500' },
	{ value: 'bg-purple-500' },
	{ value: 'bg-pink-500' },
	{ value: 'bg-white' },
	{ value: 'bg-gray-100' },
	{ value: 'bg-gray-300' },
	{ value: 'bg-gray-500' },
	{ value: 'bg-gray-700' },
	{ value: 'bg-gray-900' },
];

const backgroundColorClass = ref('transparent');

const uiConfig = reactive({
	rounded: true,
	text: true,
	outline: false,
	dense: false,
	disabled: false,
});
</script>
<style scoped></style>
