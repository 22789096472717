<template>
	<fieldset
		:class="dense ? 'p-1' : 'p-2'"
		class="relative z-0 inline-flex flex-col gap-2 rounded-lg bg-gray-500 sm:flex-row"
	>
		<legend class="sr-only text-white">{{ legend }}</legend>
		<button
			v-for="button in buttons"
			:key="button.value"
			type="button"
			class="button"
			:class="[colorClass(button), { dense }]"
			:aria-pressed="button.value === value"
			:aria-label="button.aria"
			@click="handleChange(button.value)"
		>
			<FAIcon v-if="button.icon" :icon="`fa-${button.icon}`" class="pr-2" />
			<span>{{ button.text }}</span>
		</button>
	</fieldset>
</template>

<script setup>
const emit = defineEmits(['update:value', 'click']);
const props = defineProps({
	value: { type: [String, Boolean], required: true },
	buttons: { type: Array, required: true },
	legend: { type: String, required: false },
	background: { type: String, required: false },
	dense: { type: Boolean, required: false },
});
function colorClass(button) {
	switch (props.background) {
		case 'dark':
			return button.value === props.value ? 'lightButtonSelected' : 'lightButtonDeselected';
		case 'light':
			return button.value === props.value ? 'darkButtonSelected' : 'darkButtonDeselected';
		default:
			return button.value === props.value ? 'darkButtonSelected' : 'darkButtonDeselected';
	}
}
function handleChange(value) {
	emit('update:value', value);
	emit('click', value);
}
</script>
<style>
.button {
	@apply relative inline-flex grow items-center justify-center rounded px-2 py-1 text-sm font-medium uppercase ring-0 ring-white;
	@apply transition hover:ring-2;
	@apply outline-none focus-visible:underline focus-visible:ring-2 focus-visible:ring-gray-900 focus-visible:ring-offset-2;
	&.dense {
		@apply px-1 py-0.5 text-xs;
	}
}
.lightButtonSelected {
	@apply !bg-white !text-gray-800;
}
.lightButtonDeselected {
	@apply !bg-gray-400 !text-gray-900 hover:!bg-gray-300;
}
.darkButtonSelected {
	@apply !bg-gray-900 !text-gray-50;
}
.darkButtonDeselected {
	@apply !bg-gray-500 !text-gray-50 hover:!bg-gray-600;
}
</style>
