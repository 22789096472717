<template>
	<div class="space-y-8">
		<DomainValidator :searching="searching" @search="searchDomains" />
		<div v-if="searching" class="flex items-center gap-4">
			<div class="whitespace-nowrap text-2xl font-bold">
				Checking availability for
				<span class="text-blue-700"> www.{{ domainName }} </span>
			</div>
			<ProgressBar class="!w-auto grow !items-start" />
		</div>

		<div v-if="searchResults" class="space-y-2">
			<h3 class="text-2xl font-bold">Select one of the available domains:</h3>

			<div
				v-for="domain in searchResults"
				:key="domain.name"
				class="flex items-center justify-between bg-white rounded-md p-4"
				:class="{
					'text-gray-400': !domain.available,
					'text-blue-700': domain.available && domain.exact,
					'text-green-700': domain.available && !domain.owned && !domain.exact,
					'text-orange-500': domain.owned,
				}"
			>
				<div class="flex items-center gap-4">
					<div class="w-8 grid place-items-center text-3xl">
						<FAIcon
							:icon="
								domain.exact && (domain.available || domain.owned)
									? 'fa-check'
									: domain.exact
										? 'fa-xmark'
										: 'fa-earth-americas'
							"
							size
						/>
					</div>
					<div class="flex items-center gap-6">
						<div class="text-xl mb-1">
							www.<span class="font-semibold">{{ domain.name }}</span>
						</div>
					</div>
					<div
						v-if="domain.available"
						class="rounded-full font-bold px-2.5 py-1"
						:class="{
							'bg-blue-100 text-blue-700': domain.exact,
							'bg-gray-100 text-gray-700': !domain.exact,
						}"
						v-text="domain.exact ? 'Exact match' : 'Alternative TLD'"
					></div>
					<div
						v-else
						class="rounded-full font-bold px-2.5 py-1"
						:class="{
							'bg-green-100 text-green-700': domain.available,
							'bg-orange-100 text-orange-700': domain.owned,
							'bg-gray-100 text-gray-700': !domain.available,
						}"
						v-text="domain.owned ? 'Already owned' : 'Taken'"
					></div>
				</div>
				<BaseButton
					v-if="domain.owned"
					:to="{
						// todo: re-enable this once all domain types can use the editor for settings
						// name: 'editorIndex',
						// params: { domainName: domain.name },
						name: 'domains',
					}"
				>
					View your domains
				</BaseButton>

				<BaseButton
					v-else-if="domain.available"
					:color="domain.available ? 'success' : 'gray'"
					:dense="!domain.available"
					:disabled="!domain.available"
					pill
					@click="selectName(domain.name)"
				>
					Select this domain
					<template #prepend><FAIcon icon="fas fa-lock" /></template>
				</BaseButton>
			</div>
		</div>

		<div class="rounded-md bg-white p-4">
			<h3 class="text-2xl font-bold">How to pick a good domain name:</h3>
			<ul class="mt-5 grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-4">
				<li class="suggestion">
					<p class="tldr">Keep it short and simple</p>
					You're going to put it on business cards, direct mail, novelty items,
					billboards, and more. Your primary domain name should be easily memorized, typed
					or pronounced.
				</li>
				<li class="suggestion">
					<p class="tldr">Avoid hyphens or dashes</p>
					People forget to type the hyphens, and it's harder to explain over the phone.
				</li>
				<li class="suggestion">
					<p class="tldr">Be memorable & meaningful</p>
					It's helpful if the domain name relates to you or your business name. If you're
					incorporated, you can use the word "agency" at the end of the domain, such as
					"www.JaneDoeAgency.com".
				</li>
				<li class="suggestion">
					<p class="tldr">Check the SF guidelines</p>
					<ul class="list-disc ml-2">
						<a
							href="http://sfnet.opr.statefarm.org/agency/marketing/digital_marketing/agent_domain/agent_domain_mirus.shtml"
							target="_blank"
							rel="noopener noreferrer"
						>
							<li>Agent Domain/Internet Redirect Naming Rules & Guidelines</li>
						</a>
						<a
							href="https://sfnet.opr.statefarm.org/agency/marketing/digital_marketing/agent_domain_web/domainNameLeadingPractices.shtml"
							target="_blank"
							rel="noopener noreferrer"
						>
							<li>Domain Name Leading Practices - Agent Domain</li>
						</a>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</template>
<script setup>
import { computed, ref, onMounted, watchEffect, inject } from 'vue';
import { storeToRefs } from 'pinia';

import useAgentStore from '@/stores/agent/agent';
import useDomainsStore from '@/stores/agent/agent-domains';

import { useApi } from '@/composables/useApi';

import ProgressBar from '@/components/ui/ProgressBar.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

import DomainValidator from '@/components/domains/DomainValidator';

const emit = defineEmits('update:domainName');

const agentStore = useAgentStore();
const domainsStore = useDomainsStore();

const { agent } = storeToRefs(agentStore);
const { domains } = storeToRefs(domainsStore);

const { domainName, updateDomainName } = inject('domainName');

const searching = ref(false);
const searchResults = ref(null);

const ownedDomains = computed(() => {
	return (
		domains.value?.filter(domain => !domain.is_cancelled).map(domain => domain.domain_name) ??
		[]
	);
});

async function searchDomains() {
	if (!domainName.value) {
		console.error('domain name was not supplied when searchDomains was called');
		return;
	}
	searchResults.value = null;
	searching.value = true;

	const { data } = await useApi(
		`api/v3/agents/${agent.value.associate_id}/domains/check/?${new URLSearchParams({
			domain_name: domainName.value,
		})}`,
		{
			agent: false,
			message: 'There was an issue getting the search results! Please try again later.',
		}
	).json();

	const exactOwned = ownedDomains.value.includes(domainName.value);
	const exact = {
		exact: true,
		name: domainName.value,
		owned: exactOwned,
		available: !exactOwned && data.value?.exact_match.available,
	};

	const similar = data.value?.sld_matches.map(({ domain_name, available, premium }) => {
		const owned = ownedDomains.value.includes(domain_name);
		return {
			exact: false,
			name: domain_name,
			available: available && !owned,
			owned,
			premium,
		};
	});

	searchResults.value = [exact, ...similar];
	searching.value = false;
}

function selectName(name) {
	updateDomainName(name);
	emit('continue');
}

onMounted(async () => {
	await domainsStore.ensureDomains();
});
watchEffect(() => {
	if (domainName.value !== searchResults.value?.[0]?.name) {
		searchResults.value = null;
	}
});
</script>

<style scoped>
.suggestion {
	@apply relative rounded-md border border-slate-300 p-3 pt-5 text-sm;
}
.tldr {
	@apply absolute top-0 -translate-y-1/2 transform rounded-full border bg-indigo-50 px-2 py-0.5 text-sm font-medium;
}
</style>
