<template>
	<ValidationObserver v-slot="{ invalid }" slim>
		<SortableTable
			:headers="headers"
			:items="siteNotifications"
			:loading="loading"
			class="bg-white"
			dense
			expand-all
			item-unique-key="id"
			show-pagination
			show-search
			show-title
			:padding="false"
			title="Site notifications"
		>
			<template #toolbar>
				<BaseButton color="primary" dark @click="addNotification">
					New Notification
				</BaseButton>

				<BaseDialog :loading="loading" :value.sync="showDialog" max-width="900">
					<template #header> Edit Notification </template>
					<p v-if="notification.start || notification.end" class="pb-3">
						<span v-if="notification.start">
							<b> Start: </b>
							{{
								formatDistanceToNow(new Date(notification.start), {
									addSuffix: true,
								})
							}}
						</span>
						<span v-if="notification.end">
							<b> End: </b>
							{{
								formatDistanceToNow(new Date(notification.end), {
									addSuffix: true,
								})
							}}
						</span>
						<span v-if="notification.start && notification.end">
							<b> Duration: </b>
							{{
								formatDistance(
									new Date(notification.start),
									new Date(notification.end)
								)
							}}
						</span>
					</p>

					<div class="flex flex-col gap-5 pt-2">
						<ValidationProvider v-slot="{ errors }" name="Header" rules="required">
							<TextFieldInput
								:readonly="mode === 'deleting'"
								:value.sync="notification.header"
								label="Header"
								placeholder="header"
							>
								<template v-if="errors.length > 0" #message>
									{{ errors[0] }}
								</template>
							</TextFieldInput>
						</ValidationProvider>

						<BaseTextArea
							:readonly="mode === 'deleting'"
							:rows="4"
							:value.sync="notification.message"
							label="Message"
							placeholder="Message"
							show-placeholder
						/>

						<vc-date-picker
							v-if="mode !== 'deleting'"
							:columns="$screens({ default: 1, lg: 2 })"
							:is-expanded="$screens({ default: true, lg: false })"
							:minute-increment="15"
							:model-config="{
								type: 'string',
							}"
							:step="1"
							:value="{
								start: notification.start,
								end: notification.end,
							}"
							class="self-center"
							is-range
							is-required
							mode="dateTime"
							@input="updateRange"
						/>
						<div v-else class="flex gap-5">
							<TextFieldInput
								:value="format(new Date(notification.start), dateFormat)"
								class="grow"
								label="Start"
								readonly
							/>

							<TextFieldInput
								:value="format(new Date(notification.end), dateFormat)"
								class="grow"
								label="End"
								readonly
							/>
						</div>
						<div class="flex gap-3">
							<BaseSelect
								id="notification-variant"
								:items="notificationTypes"
								:readonly="mode === 'deleting'"
								:value.sync="notification.variant"
								class="basis-1/2"
								label="Variant"
								outlined
								show-label
							/>

							<CheckboxInput
								:checked.sync="notification.show_dashboard"
								class="my-4 basis-1/4"
								name="show-dashboard"
							>
								Show on Dashboard
							</CheckboxInput>
							<CheckboxInput
								:checked.sync="notification.show_mxeditor"
								class="my-4 basis-1/4"
								name="show-mxeditor"
							>
								Show on MXEditor
							</CheckboxInput>
						</div>
					</div>
					<template #actions>
						<BaseButton text @click="cancelChanges"> Cancel </BaseButton>
						<BaseButton
							:color="mode === 'deleting' ? 'error' : 'primary'"
							:disabled="
								(mode !== 'deleting' && invalid) ||
								!notification.start ||
								!notification.end
							"
							@click="saveChanges"
						>
							{{ mode === 'deleting' ? 'Delete' : 'Save' }}
						</BaseButton>
					</template>
				</BaseDialog>
			</template>

			<template #item.variant="{ variant }">
				<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
					<div
						:class="{
							'bg-green-600 text-white': variant === 'success',
							'bg-blue-600 text-white': variant === 'info',
							'bg-red-600 text-white': variant === 'error',
							'bg-yellow-400 text-black': variant === 'warning',
						}"
						class="rounded-full px-2 py-1 text-center"
					>
						{{ variant }}
					</div>
				</td>
			</template>
			<template #item.header="{ header }">
				<span class="truncate whitespace-normal">
					{{ header }}
				</span>
			</template>
			<template #item.start="{ start }">
				{{ format(new Date(start), dateFormat) }}
			</template>
			<template #item.end="{ end }">
				{{ format(new Date(end), dateFormat) }}
			</template>
			<template #item.actions="item">
				<button
					class="mr-2 text-xl text-orange"
					title="Edit"
					type="button"
					@click="updateNotification({ item, mode: 'editing' })"
				>
					<FAIcon icon="pencil" /><span class="sr-only">Edit</span>
				</button>
				<button
					class="mr-2 text-xl text-green-700"
					title="Duplicate"
					type="button"
					@click="updateNotification({ item, mode: 'adding' })"
				>
					<FAIcon icon="copy" /><span class="sr-only">Duplicate</span>
				</button>

				<button
					class="text-xl text-red-700"
					title="Delete"
					type="button"
					@click="updateNotification({ item, mode: 'deleting' })"
				>
					<FAIcon icon="trash" /><span class="sr-only">Delete</span>
				</button>
			</template>

			<template #item_expanded="{ message }">
				<div class="p-5">
					<span class="whitespace-normal" v-html="message" />
				</div>
			</template>
		</SortableTable>
	</ValidationObserver>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import { format, isWithinInterval, formatDistanceToNow, formatDistance } from 'date-fns';

import useNotificationStore from '@/stores/site/notifications';

import BaseSelect from '@/components/ui/BaseSelect';
import BaseButton from '@/components/ui/BaseButton';
import CheckboxInput from '@/components/ui/CheckboxInput.vue';
import SortableTable from '@/components/ui/SortableTable';
import TextFieldInput from '@/components/ui/TextFieldInput';
import BaseTextArea from '@/components/ui/BaseTextArea.vue';
import BaseDialog from '@/components/ui/BaseDialog';

const headers = [
	{ sortable: false, text: 'Type', value: 'variant' },
	{ sortable: false, text: 'Location', value: 'location' },
	{ sortable: true, text: 'Active', value: 'active' },
	{ sortable: true, text: 'Header', value: 'header', filterable: true },
	{ sortable: true, text: 'Start Date', value: 'start' },
	{ sortable: true, text: 'End Date', value: 'end' },
	{ sortable: false, text: 'Actions', value: 'actions' },
];
const notificationTypes = ['info', 'warning', 'error', 'success'];
const dateFormat = `MMM dd yyy, HH:mm`;

const notificationStore = useNotificationStore();

const siteNotifications = computed(() =>
	notificationStore.notifications?.map(n => {
		let location = 'none';
		if (n.show_dashboard && n.show_mxeditor) {
			location = 'both';
		} else if (n.show_dashboard) {
			location = 'dashboard';
		} else if (n.show_mxeditor) {
			location = 'mxeditor';
		}
		return {
			...n,
			active: isWithinInterval(new Date(), {
				start: new Date(n.start),
				end: new Date(n.end),
			}),
			location: location,
		};
	})
);

const loading = ref(false);

const showDialog = ref(false);
const mode = ref(null);
const id = ref(null);

const notification = reactive({
	header: ``,
	message: ``,
	variant: `info`,
	start: ``,
	end: ``,
	show_dashboard: false,
	show_mxeditor: false,
});

function resetNotification() {
	notification.header = ``;
	notification.message = ``;
	notification.variant = `info`;
	notification.start = null;
	notification.end = null;
	notification.show_dashboard = true;
	notification.show_mxeditor = false;
}

function updateNotification({ item, mode: newMode }) {
	const { header, message, variant, start, end, id: newId, show_dashboard, show_mxeditor } = item;
	notification.header = header;
	notification.message = message;
	notification.variant = variant;
	notification.start = start;
	notification.end = end;
	notification.show_dashboard = show_dashboard;
	notification.show_mxeditor = show_mxeditor;
	mode.value = newMode;
	id.value = newId;
	showDialog.value = true;
}

function addNotification() {
	resetNotification();
	showDialog.value = true;
}
function cancelChanges() {
	mode.value = undefined;
	showDialog.value = false;
	resetNotification();
}
async function saveChanges() {
	loading.value = true;
	let action;
	switch (mode.value) {
		case 'editing':
			action = `updateNotification`;
			break;
		case 'deleting':
			action = `deleteNotification`;
			break;
		default:
			action = `saveNotification`;
	}
	await notificationStore[action]({ json: { ...notification }, id: id.value });
	loading.value = false;
	showDialog.value = false;
	mode.value = null;
	id.value = undefined;
	resetNotification();
}
function updateRange({ start, end }) {
	notification.start = start;
	notification.end = end;
}
</script>
