<template>
	<div
		class="w-full rounded-xl transition-all duration-300"
		:class="{
			'p-4 border-2 border-gray-300 bg-gray-100': ['default', 'comfortable'].includes(
				viewMode
			),
			'bg-gray-100 p-2': viewMode === 'compact' && expanded,
		}"
	>
		<button
			type="button"
			class="w-full flex items-center text-left justify-between"
			:disabled="viewMode === 'comfortable'"
			@click="viewMode !== 'comfortable' && (expanded = !expanded)"
		>
			<div class="flex gap-4 items-center basis-2/5">
				<SFProfileImage
					:associate_id="appointment.assigned_to.associate_id"
					:alt="appointment.assigned_to.name"
					class="transition-all duration-500 ease-out rounded-full"
					:width="viewMode === 'compact' ? '32px' : '64px'"
				/>
				<div
					class="flex gap-x-2"
					:class="viewMode === 'compact' ? 'flex-wrap' : 'flex-col'"
				>
					<span class="font-semibold">{{ appointment.timeframe_label }}</span>
					<span v-text="appointment.assigned_to.name"></span>
				</div>
			</div>
			<div class="basis-2/5">
				<span class="font-semibold capitalize">{{ appointment.communication_medium }}</span>
				with
				<span class="font-semibold">{{ appointment.name }}</span>
			</div>
			<FAIcon
				class="transition-[opacity,padding,transform] ease-out duration-500"
				:class="[
					viewMode === 'comfortable' && 'opacity-0',
					viewMode === 'compact' ? 'p-0' : 'p-4',
					{ 'rotate-180': expanded },
				]"
				icon="chevron-down"
			/>
		</button>
		<BaseExpand :expanded="expanded || viewMode === 'comfortable'">
			<div class="flex items-end flex-col">
				<div
					class="cursor-default rounded-lg p-4 transition-all bg-white duration-500 ease-out w-full"
					:class="[viewMode === 'compact' ? 'mt-2' : 'mt-4']"
					@click.stop
				>
					<div class="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3">
						<div>
							<h3>Meeting With</h3>
							<span>{{ appointment.name }}</span>
						</div>
						<div>
							<h3>Email</h3>
							<span>{{ appointment.email_address ?? 'No email provided' }}</span>
						</div>
						<div>
							<h3>Phone</h3>
							<span>{{
								appointment.phone_number ?? 'No phone number provided'
							}}</span>
						</div>
						<div>
							<h3>Type</h3>
							<span class="capitalize">{{ appointment.communication_medium }}</span>
						</div>
						<div>
							<h3>Location</h3>
							<div v-if="appointment.location">
								<div>{{ appointment.location.office_location.street1 }}</div>
								<div>{{ appointment.location.office_location.street2 }}</div>
								<div>
									{{ appointment.location.office_location.city }},{{
										appointment.location.office_location.state
									}}
								</div>
							</div>
							<span v-else>No location provided.</span>
						</div>
						<div>
							<h3>Topic</h3>
							<span class="capitalize">{{ appointment.topics.join(', ') }}</span>
						</div>
					</div>
				</div>
				<div
					v-if="showActionButtons"
					class="transition-all duration-500 ease-out flex flex-wrap justify-end w-full gap-2"
					:class="viewMode === 'compact' ? 'mt-2' : 'mt-4'"
				>
					<BaseDialog>
						<template #activator="{ openDialog }">
							<BaseButton color="red" text @click="openDialog"
								><template #prepend><FAIcon icon="xmark" /></template>Cancel
								Appointment
							</BaseButton>
						</template>
						<template #header> Confirm Cancellation </template>
						<p>Are you sure you want to cancel this appointment?</p>
						<p>
							An email will be sent to
							<span class="font-semibold">{{ appointment.email_address }}</span
							>.
						</p>
						<template #actions="{ closeDialog }">
							<BaseButton color="darkGray" text @click="closeDialog">
								Keep Appointment
							</BaseButton>
							<BaseButton color="red" @click="cancelAppointment(appointment.id)">
								Cancel Appointment
							</BaseButton>
						</template>
					</BaseDialog>
					<BaseDialog>
						<template #activator="{ openDialog }">
							<BaseButton color="darkGray" text @click="openDialog">
								<template #prepend><FAIcon icon="calendar" /></template>
								Reschedule
							</BaseButton>
						</template>
						<template #header> Reschedule Appointment </template>
						<div class="space-y-2">
							<p>
								An email will be sent to
								<span class="font-semibold">{{ appointment.name }}</span
								>, asking them to confirm the new appointment details.
							</p>
							<div>
								<label
									for="rescheduleAppointmentDate"
									class="text-xs text-gray-600 block"
									>Date</label
								>
								<input
									id="rescheduleAppointmentDate"
									v-model="localDate"
									type="date"
									class="w-fit rounded bg-white !p-1 text-center border-gray-500 focus:ring focus:ring-orange-500 invalid:border-red-500 invalid:text-red-600"
								/>
							</div>
							<div>
								<label
									for="rescheduleAppointmentTime"
									class="text-xs text-gray-600 block"
									>Time</label
								>
								<input
									id="rescheduleAppointmentTime"
									v-model="localTime"
									type="time"
									class="w-fit rounded bg-white !p-1 text-center border-gray-500 focus:ring focus:ring-orange-500 invalid:border-red-500 invalid:text-red-600"
								/>
							</div>
							<fieldset class="flex flex-col gap-2">
								<legend class="text-xs text-gray-600">Duration</legend>
								<RadioInput
									name="rescheduleAppointmentDuration"
									value="30"
									:checked="appointment.duration === '30'"
									@select="localDuration = 30"
								>
									30 minutes
								</RadioInput>
								<RadioInput
									name="rescheduleAppointmentDuration"
									value="60"
									:checked="appointment.duration === '60'"
									@select="localDuration = 60"
								>
									60 minutes
								</RadioInput>
							</fieldset>
						</div>
						<template #actions="{ closeDialog }">
							<BaseButton color="darkGray" text @click="closeDialog">
								Cancel
							</BaseButton>
							<BaseButton @click="rescheduleAppointment(appointment.id)">
								Reschedule
							</BaseButton>
						</template>
					</BaseDialog>
				</div>
			</div>
		</BaseExpand>
	</div>
</template>
<script setup>
import { ref } from 'vue';
import BaseExpand from '@/components/ui/BaseExpand.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import RadioInput from '@/components/ui/RadioInput.vue';
import useAppointmentStore from '@/stores/agent/agent-appointments';
import SFProfileImage from '@/components/common/SFProfileImage';

const { cancelAppointment, rescheduleAppointment } = useAppointmentStore();

const props = defineProps({
	appointment: { type: Object, required: true },
	viewMode: { type: String, required: true },
	showActionButtons: { type: Boolean, required: true },
});

const expanded = ref(false);
const localDate = ref(props.appointment.date);
const localTime = ref(props.appointment.time);
</script>
<style scoped lang="scss">
h3 {
	font-size: 0.75rem;
	line-height: 1.25rem;
	font-weight: 700;
	letter-spacing: 0.075em;
	line-height: 1.25;
	text-transform: uppercase;
	color: #4b5563;
}
</style>
