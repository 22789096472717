<template>
	<div class="rounded-lg border-2 border-gray-300 bg-gray-100 p-4">
		<div class="flex w-full flex-col gap-4 lg:flex-row">
			<div class="flex grow items-center gap-4">
				<div class="flex flex-initial items-center gap-2">
					<img alt="" :src="images[card.card_type.toLowerCase().replace(' ', '_')]" />
					<h4 class="mt-0.5 whitespace-nowrap text-xl text-black">
						<b>Credit Card</b>
						Ending in {{ card.last_4 }}
					</h4>
				</div>
				<div
					v-if="card.default"
					class="flex grow-0 items-center gap-1 rounded-full border-2 border-green-800 bg-green-500/20 px-2 py-0.5 text-green-800"
				>
					<span class="text-sm font-bold">Selected</span>
					<FAIcon icon="fa-circle-check" size="xs" />
				</div>
			</div>

			<div class="flex items-center justify-end gap-2">
				<template v-if="deleting">
					<div class="flex items-center justify-end gap-2">
						<h3 v-if="!card.default" class="text-right font-bold">
							Delete this Payment Method?
						</h3>
						<BaseButton :disabled="saving" color="gray" @click="deleting = false">
							Cancel
						</BaseButton>
						<BaseButton
							v-if="!card.default"
							:loading="saving"
							color="error"
							@click="onDelete"
						>
							Confirm
						</BaseButton>
					</div>
				</template>

				<template v-else>
					<div class="flex items-center justify-end gap-2">
						<BaseButton
							v-if="!card.default"
							color="green"
							:loading="saving"
							class="lg:ml-2"
							@click="onUseDefault"
						>
							Use This Card
						</BaseButton>
						<BaseButton color="primary" @click="onEdit">
							<template #prepend> <FAIcon icon="pencil" /> </template>Edit
						</BaseButton>
						<BaseButton color="error" @click="deleting = true">
							<template #prepend> <FAIcon icon="times" /> </template>Delete
						</BaseButton>
					</div>
				</template>

				<BaseButton color="gray" text class="h-full" @click="togglePanel">
					Details
					<template #append>
						<FAIcon
							icon="chevron-down"
							:class="open ? 'rotate-180' : 'rotate-0 pb-0.5'"
							class="transition-all duration-300"
						/>
					</template>
				</BaseButton>
			</div>
		</div>

		<BaseAlert v-if="deleting && card.default" type="error" class="mt-4">
			Cannot remove selected payment method! Please select a new payment method first
		</BaseAlert>

		<BaseExpand :expanded="open">
			<div class="mt-4">
				<div class="text-xl font-bold">
					{{ card.cardholder_name || 'Missing Cardholder Name' }}
				</div>
				<div class="pb-3 text-sm text-gray-500">Cardholder Name</div>
				<div class="text-xl font-bold">
					{{ card.billing_address.street_address || 'Missing Street Address' }}
					<br />
					{{ card.billing_address.extended_address }}
					<br v-if="card.billing_address.extended_address" />
					{{ card.billing_address.locality || 'Missing City' }}
					{{ card.billing_address.region || 'Missing State' }},
					{{ card.billing_address.postal_code || 'Missing Zip Code' }}
				</div>
				<div class="pb-3 text-sm text-gray-500">Street Address</div>
				<div class="text-xl font-bold">
					{{ card.expiration_month || 'Missing Expiration Month' }}/{{
						card.expiration_year || 'Missing Expiration Year'
					}}
				</div>
				<div class="text-sm text-gray-500">Expiration Date</div>
			</div>
		</BaseExpand>
		<template v-if="info">
			<BaseAlert v-if="info.expiring_card" type="error">
				Selected Payment Method is expiring soon, please update your payment method
			</BaseAlert>
			<BaseAlert v-if="info.expired_card" type="error">
				Selected card is expired, please update your payment method
			</BaseAlert>
		</template>
	</div>
</template>
<script setup>
import BaseExpand from '@/components/ui/BaseExpand';
import american_express from '@/assets/images/footer/american_express_32.png';
import discover from '@/assets/images/footer/discover_32.png';
import mastercard from '@/assets/images/footer/mastercard_32.png';
import visa from '@/assets/images/footer/visa_32.png';
import { ref } from 'vue';

import usePaymentStore from '@/stores/agent/agent-payment';

import BaseAlert from '@/components/ui/BaseAlert';

import BaseButton from '@/components/ui/BaseButton.vue';

const images = {
	american_express,
	discover,
	mastercard,
	visa,
};

const paymentStore = usePaymentStore();

const emit = defineEmits('edit');
const props = defineProps({
	card: Object,
	info: Object,
});

const open = ref(false);
const deleting = ref(false);
const saving = ref(false);

async function onDelete() {
	saving.value = true;
	await paymentStore.deleteMethod(props.card.token);
	saving.value = false;
}

async function onUseDefault() {
	saving.value = true;
	await paymentStore.selectMethod(props.card.token);
	saving.value = false;
}

function onEdit() {
	emit('edit', props.card.token);
}

function togglePanel() {
	open.value = !open.value;
}
</script>
<style scoped>
.card-card {
	background-color: rgb(232, 232, 232);
}
</style>
