<template>
	<BaseAlert :icon="false" class="items-start justify-between" dense type="warn">
		<ProgressBar v-if="loading" indeterminate />
		<div>
			<p>
				Hey! It looks like this invoice has some failed transactions and is not fully paid!
			</p>
			<p>
				If you know why the transaction(s) have failed, and have fixed the problem, go ahead
				and retry the payment:
			</p>
		</div>

		<template #action>
			<BaseButton :dark="loading" color="gray" :disabled="loading" @click="retry">
				retry
			</BaseButton>
		</template>
	</BaseAlert>
</template>

<script setup>
import { ref } from 'vue';
import useAlertStore from '@/stores/alerts';
import BaseAlert from '@/components/ui/BaseAlert';
import ProgressBar from '@/components/ui/ProgressBar';
import { useApi } from '@/composables/useApi';
import BaseButton from '@/components/ui/BaseButton.vue';

const props = defineProps({
	invoiceNumber: { type: Number, required: true },
	reload: { type: Function, required: true },
});

const loading = ref(false);
async function retry() {
	loading.value = true;
	const message = `There was an issue with retrying invoice #${props.invoiceNumber}. Please try again later!`;
	try {
		const { data } = await useApi(`receipt/${props.invoiceNumber}/retry/`, {
			agent: true,
			message,
		});

		if (data.success) {
			props.reload();
		}
	} catch (error) {
		const alertStore = useAlertStore();

		alertStore.addAlert({ error, message });
	} finally {
		loading.value = false;
	}
	return Promise.resolve();
}
</script>

<style scoped module lang="scss"></style>
