<template>
	<ValidationObserver v-slot="{ valid }" class="container">
		<div class="px-3 pb-3 text-gray-500 dark:text-gray-100">
			<h3 class="">Link Generator for Tracking Codes/Campaign IDs</h3>

			<p class="text-md">
				This section allows you to generate a new tracking link which you can use in your
				online marketing campaigns.
			</p>

			<div
				class="mx-3 my-5 grid grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-4 lg:flex-row xl:grid-cols-7"
			>
				<ValidationProvider
					v-slot="{ errors, validate }"
					name="Domain"
					rules="required"
					slim
				>
					<BaseSelect
						id="domain-select"
						:value.sync="domain"
						:items="domainList"
						:error-messages="errors"
						label="Select a Domain"
						item-text-key="domain_name"
						item-value-key="domain_name"
						outlined
						show-label
						value-type="object"
						@update:value="validate"
					/>
				</ValidationProvider>

				<BaseSelect
					v-if="showLanguage"
					id="languageChoice"
					:items="languageOptions"
					:value.sync="languageChoice"
					label="Language"
					outlined
					show-label
				/>

				<ValidationProvider v-slot="{ errors, validate }" name="Landing Page" slim>
					<BaseSelect
						id="landing-page-select"
						:value.sync="landingPage"
						:items="landingPages"
						:error-messages="errors"
						label="Landing Page"
						outlined
						show-label
						capitalize
						value-type="object"
						@update:value="validate"
					/>
				</ValidationProvider>
				<TextFieldInput
					label="State Code"
					:hint="`${stateCodePlusCode} (read only)`"
					:value="agent.state_code"
					disabled
					class="shrink-0"
				/>

				<TextFieldInput
					ref="agentCode"
					label="Agent Code"
					:value="agent.agent_code"
					class="shrink-0"
					disabled
				/>

				<ValidationProvider
					v-slot="{ errors, validate }"
					name="Traffic Source"
					rules="required"
					slim
				>
					<BaseSelect
						id="traffic-source-select"
						:value.sync="trafficSource"
						:items="trafficSources"
						:error-messages="errors"
						label="Traffic Source"
						shadow
						outlined
						show-label
						value-type="object"
						@update:value="validate"
					/>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors, validate }"
					name="Code"
					slim
					:rules="{ required: true, between: { min: 1, max: 999 } }"
				>
					<TextFieldInput
						v-model="code"
						label="Creative Code"
						:error-messages="errors"
						maxlength="3"
						minlength="3"
						placeholder="001 - 699"
						pattern="\d{3}"
						min="001"
						max="699"
						required
						class="shrink-0"
						@update:value="validate"
					/>
				</ValidationProvider>
			</div>

			<div class="mx-3 mt-4 flex items-center space-x-4">
				<div
					id="copy"
					class="relative rounded bg-gray-100 px-4 py-4 text-sm font-medium dark:bg-gray-600 lg:text-lg"
				>
					<p
						class="absolute top-0 -translate-y-1/2 transform rounded border bg-white px-1 py-0.5 text-xs font-medium dark:border-gray-800 dark:bg-gray-600"
					>
						Generated Link:
					</p>
					{{ fullUrlString }}
				</div>

				<BaseButton :disabled="!valid" color="primary" @click="copyToClipboard()">
					<FAIcon icon="copy" />
					<span class="ml-1 text-sm font-medium">Copy</span>
				</BaseButton>
			</div>

			<BaseSnackbar :display="displaySnackbar" :timeout="5000">
				<BaseAlert :type="copiedLinkType">
					{{ copiedLinkMessage }}
				</BaseAlert>
			</BaseSnackbar>

			<div class="mx-3 mb-3 mt-8">
				<BaseExpansionList :items="linkGeneratorFAQs">
					<template #content="{ content }">
						<div class="p-4" v-html="content"></div>
					</template>
				</BaseExpansionList>
			</div>
			<div class="mx-3 mb-3 mt-4" />
		</div>
	</ValidationObserver>
</template>

<script setup>
import { computed, onMounted, ref, watch, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { extend } from 'vee-validate';

import useDomainStore from '@/stores/agent/agent-domains';
import useAgentStore from '@/stores/agent/agent';

import BaseSnackbar from '@/components/ui/BaseSnackbar';
import BaseAlert from '@/components/ui/BaseAlert';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseExpansionList from '@/components/ui/BaseExpansionList.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';

import {
	allRouteOptions,
	availableLanguageOptions,
	trafficSources,
	linkGeneratorFAQs,
} from '@/components/campaign/linkGeneratorConstants';

extend('code', {
	validate: value => {
		return value.match(/[^\d{3}$]/) || `Code must be exactly three numbers`;
	},
});

const domainStore = useDomainStore();
const agentStore = useAgentStore();

const { agent } = storeToRefs(agentStore);
const { domains } = storeToRefs(domainStore);

const domain = ref(null);
const languageChoice = ref('en');
const landingPage = ref(null);
const trafficSource = ref(null);
const code = ref(`001`);
const routes = ref([]);

const displaySnackbar = ref(false);
const copiedLinkType = ref('success');
const copiedLinkMessage = ref('');

const domainList = computed(() => {
	return domains.value?.filter(domain => domain.active) ?? [];
});

const languageOptions = computed(() => {
	return domain.value?.allowed_languages
		?.map(item => availableLanguageOptions[item])
		.filter(item => !!item);
});

const showLanguage = computed(() => {
	return languageOptions.value?.length > 1;
});

const landingPages = computed(() => {
	if (!domain.value) {
		return [{ value: 'root', text: 'Homepage', key: 'root' }];
	}
	const options = routes.value
		.map(route => {
			return allRouteOptions.find(
				option =>
					// English matches the unique key directly, no suffix
					(option.key === route.unique_key && languageChoice.value === 'en') ||
					// Other languages have a suffix
					[`${option.key}_${languageChoice.value}`].includes(route.unique_key) ||
					// route unique_key for homepages don't match the option key completely
					(['m1root', 'm2root'].includes(route.unique_key) &&
						option.key === 'root' &&
						// Homepage is always available for non-parked domains
						!['parked', 'old_parked'].includes(domain.value?.product_type))
			);
		})
		.filter(option => option);

	// if (!['parked', 'old_parked'].includes(domain.value?.product_type)) {
	// 	options.push({ value: 'root', text: 'Homepage', key: 'root' });
	// }
	options.sort((a, b) => allRouteOptions.indexOf(a) - allRouteOptions.indexOf(b));
	return options;
});

const agentCodePlusState = computed(() => agent.value.state_code + agent.value.agent_code);
const stateCodePlusCode = computed(() => agent.value.state + ' Code');

const selectedNumberString = computed(() => (code.value || '1').padStart(3, '0'));

const fullUrlString = computed(() => {
	const domainString = domain.value?.domain_name ?? '[ One of your domains ]';
	const languageCode = languageChoice.value !== 'en' ? `/${languageChoice.value}` : '';
	const landingPageString = landingPage.value?.value
		? `${landingPage.value.value.toLowerCase()}/`
		: '';
	const trafficSourceString = trafficSource.value?.value.toLowerCase() ?? '[ Traffic Source ]';

	return `https://${domainString}${
		languageChoice.value ? languageCode : ''
	}/${landingPageString}?cmpid=${agentCodePlusState.value}${trafficSourceString}${
		selectedNumberString.value
	}`;
});

async function copyToClipboard() {
	displaySnackbar.value = false;
	await nextTick();
	displaySnackbar.value = true;

	if (!navigator.clipboard) {
		copiedLinkMessage.value = "Unable to access your browser's clipboard.";
		copiedLinkType.value = 'error';
		return;
	}

	navigator.clipboard.writeText(fullUrlString.value).then(
		() => {
			copiedLinkMessage.value = 'Copied to your Clipboard!';
			copiedLinkType.value = 'success';
		},
		() => {
			copiedLinkMessage.value = 'Unable to copy to your Clipboard.';
			copiedLinkType.value = 'error';
		}
	);
}

onMounted(async () => {
	await domainStore.ensureDomains();
});

watch(
	() => domain.value,
	async () => {
		if (domain.value) {
			const allRoutes = await domainStore.getRoutes(domain?.value.domain_name);
			routes.value = allRoutes.filter(route => !route.disabled);
			landingPage.value = landingPages?.value[0];
			languageChoice.value = domain.value?.options?.language ?? 'en';
		}
	},
	{ deep: true }
);

defineExpose({
	domain,
	routes,
	languageChoice,
	trafficSource,
	code,
	languageOptions,
	landingPages,
	fullUrlString,
	showLanguage,
	landingPage,
	trafficSources,
	availableLanguageOptions,
});
</script>
