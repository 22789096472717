<template>
	<div class="space-y-4 text-gray-700 dark:text-gray-100">
		<div class="flex w-full flex-wrap gap-2">
			<div class="flex items-center justify-center gap-1 rounded-md bg-gray-200 p-2">
				<span class="text-md">Status</span>
				<span class="text-lg font-semibold leading-none">
					{{ account.paused ? 'Paused' : 'Active' }}
				</span>
			</div>
			<div class="flex items-center justify-center gap-1 rounded-md bg-gray-200 p-2">
				<span class="text-md">Number of lines</span>
				<span class="text-lg font-semibold leading-none">
					{{ account.pool_size }}
				</span>
			</div>
			<div class="flex items-center justify-center gap-1 rounded-md bg-gray-200 p-2">
				<span class="text-md">Target number</span>
				<span class="text-lg font-semibold leading-none">
					{{ FormatNumber(account.destination_number) }}
				</span>
			</div>
			<div class="flex items-center justify-center gap-1 rounded-md bg-gray-200 p-2">
				<span class="text-md">Minutes Used</span>
				<span
					class="text-lg font-semibold leading-none"
					:class="{ [$style.important]: over || almostOver }"
				>
					{{ account.usage }} / {{ account.budget_minutes }}
				</span>
			</div>
			<div
				v-if="over"
				class="flex items-center justify-center gap-1 rounded-md bg-gray-200 p-2"
			>
				<span class="text-lg font-medium leading-none">
					{{ overageAmount }}
				</span>
				<span class="text-md font-normal">Overage Fee</span>
				<BaseDialog max-width="800">
					<template #activator="{ openDialog }">
						<BaseButton
							dense
							text
							color="primary"
							class="mb-0 mt-0 p-0"
							@click="openDialog"
						>
							<FAIcon icon="message-question" size="xl" />
						</BaseButton>
					</template>
					<template #header>Overage Status</template>
					<div class="rounded bg-white p-4">
						<p>
							You have used {{ account.usage }} minutes of your monthly budgeted
							{{ account.budget_minutes }}
							minutes.
						</p>
						<p v-if="over">
							This will result in a overage fee of {{ overageAmount }} on your next
							billing day
						</p>
						<p v-else>
							If you use more than {{ account.budget_minutes }} minutes, you will be
							charged {{ overageRate }} for every minute over your budget
						</p>
						<p>
							You can pause your Call Tracking service, which means call tracking
							numbers will not not be displayed to visitors. Customers can still reach
							you by the your calltracking numbers, and your overageAmount fee will
							not be increased. At the beginning of your next billing cycle, your Call
							Tracking service will be automatically restored.
						</p>
						<p>
							If instead you would like Call Tracking to remain active and pay an
							overageAmount fee, simply leave the service unpaused.
						</p>
						<p>
							Options for pausing, un-pausing, and automatic pausing can be found
							pressing CONFIGURE at the top of this page.
						</p>
					</div>
					<template #actions="{ closeDialog }">
						<BaseButton color="primary" @click="closeDialog"> Got It! </BaseButton>
					</template>
				</BaseDialog>
			</div>
		</div>
		<div class="flex flex-col gap-4">
			<h3 class="text-2xl font-semibold">Settings</h3>
			<div class="flex flex-col gap-4 text-left">
				<button
					class="flex items-center gap-2 text-left"
					@click="() => (paused = !paused)"
					@keydown.enter="() => (paused = !paused)"
				>
					<BaseToggle :value.sync="paused" />
					<div class="flex flex-col">
						<strong class="leading-5">Manually pause call tracking</strong>
						<span class="text-sm text-gray-800">
							Hide call tracking numbers so that no extra charge is incurred. Call
							tracking will automatically resume with your next billing cycle.
						</span>
					</div>
				</button>
				<button
					class="flex items-center gap-2 text-left"
					@click="autoPause = !autoPause"
					@keydown.enter="autoPause = !autoPause"
				>
					<BaseToggle :value.sync="autoPause" />
					<span class="flex flex-col">
						<strong class="leading-5">Automatically pause before overcharge</strong>
						<span class="text-sm text-gray-800">
							Calltracking will be paused automatically before an overage is incurred.
						</span>
					</span>
				</button>
			</div>
			<BaseButton
				color="primary"
				class="mr-auto"
				:disabled="!changed || loading"
				@click="saveChangedOptions"
			>
				{{ loading ? 'Saving...' : 'Save' }}
			</BaseButton>
		</div>
	</div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import useCalltrackingStore from '@/stores/agent/agent-calltracking';

import BaseButton from '@/components/ui/BaseButton.vue';
import BaseToggle from '@/components/ui/BaseToggle';
import BaseDialog from '@/components/ui/BaseDialog';

function FormatNumber(number) {
	return `(${number.substring(0, 3)})-${number.substring(3, 6)}-${number.substring(6, 10)}`;
}

const calltrackingStore = useCalltrackingStore();
const { account } = storeToRefs(calltrackingStore);

const autoPause = ref(false);
const paused = ref(account.value?.paused);
const loading = ref(account.value?.auto_pause);

const overage = computed(
	() => Number(account.value?.usage) - Number(account.value?.budget_minutes)
);
const overageAmount = computed(() => {
	const amount = account.value?.overage;
	const rate = account.value?.overage_rate;
	return (amount * rate).toFixed(2);
});
const overageRate = computed(() => Number(account?.value?.overage_rate)?.toFixed(2));
const over = computed(() => overage.value > 0);
const almostOver = computed(() => overage.value > -15);
const changed = computed(
	() => paused.value !== account.value?.paused || autoPause.value !== account.value?.auto_pause
);

async function saveChangedOptions() {
	loading.value = true;

	await calltrackingStore.update({
		paused: paused.value,
		auto_pause: autoPause.value,
	});

	loading.value = false;
}
</script>

<style scoped module lang="scss">
.important {
	color: lightslategray;
	background: white;
	border-radius: 25px;
	padding: 0 7px;
}
</style>
