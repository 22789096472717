<template>
	<section
		class="border-gray grid grid-cols-[200px_1fr_max-content] gap-2 overflow-hidden rounded border-2 border-solid h-full"
		height="100%"
	>
		<h5 class="col-span-2 col-start-2 row-start-1 mr-2 mt-2 text-xl font-bold text-black">
			{{ title }}
		</h5>
		<img
			width="100%"
			role="presentation"
			class="col-start-1 row-span-4 row-start-1 aspect-[25/14] h-full max-w-4xl object-cover"
			:src="image"
			alt=""
		/>

		<p class="col-span-2 col-start-2 row-start-2">
			<span v-html="description" />&nbsp;
			<a
				:href="urlWithID"
				target="_blank"
				rel="nofollow noreferrer"
				:aria-label="`Read more about ${title}`"
			>
				Read more
			</a>
		</p>

		<HelpPleaseNote
			v-show="blacklisted && !currentlyBlacklisted"
			:heading-level="6"
			class="col-span-2 col-start-2 row-start-3 m-0"
		>
			this article will be replaced on save.
		</HelpPleaseNote>

		<BaseButton
			:color="blacklisted ? 'primary' : 'error'"
			class="col-start-3 row-start-4 mb-2 mr-2 self-end"
			:aria-label="button.ariaLabel"
			aria-live="off"
			@click="handleChange"
		>
			{{ button.text }}
		</BaseButton>
	</section>
</template>

<script setup>
import { computed } from 'vue';
import HelpPleaseNote from '@/components/MXEditor/help/HelpPleaseNote';
import useEditorStore from '@/stores/editor';
import { storeToRefs } from 'pinia';
import BaseButton from '@/components/ui/BaseButton';

// model: { prop: 'blacklisted', event: 'change' },
const emit = defineEmits(['update:blacklisted']);
const props = defineProps({
	currentlyBlacklisted: { type: Boolean, default: false },
	blacklisted: { type: Boolean, required: true },

	description: { type: String, required: true },
	title: { type: String, required: true },
	image: { type: String, required: true },
	url: { type: String, required: true },
	pk: { type: Number, required: true },
});

const editorStore = useEditorStore();
const { agent } = storeToRefs(editorStore);

const agentID = computed(() => agent.value?.associate_id);
const urlWithID = computed(() => `${props.url}?agentAssociateId=${agentID.value}`);

function handleChange() {
	emit('update:blacklisted', !props.blacklisted);
}
const button = computed(() => {
	let content = {
		text: `Hide Article`,
		ariaLabel: `Hide Article titled ${props.title}`,
		color: 'primary',
	};
	if (props.currentlyBlacklisted) {
		content.ariaLabel = props.blacklisted
			? `Unhide Article titled ${props.title}`
			: `Keep article titled ${props.title} hidden`;

		content.text = props.blacklisted ? `Unhide Article` : `Keep article hidden`;
	} else {
		content.ariaLabel = props.blacklisted
			? `Keep article titled ${props.title} visible`
			: `Hide Article titled ${props.title}`;
		content.text = props.blacklisted ? `Keep article visible` : `Hide Article`;
	}
	return content;
});
</script>

<style lang="scss"></style>
