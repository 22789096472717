<template>
	<div
		v-if="user && agent"
		class="container flex flex-col items-stretch justify-between pb-4 lg:flex-row lg:items-start"
	>
		<section class="flex w-full grow-0 flex-wrap gap-1 p-0 md:w-auto lg:gap-3">
			<h3 class="mb-0 w-full px-0 text-2xl leading-none">Agent</h3>
			<hr class="w-full" />

			<h4 class="w-full text-2xl">{{ agent.letterhead_name }}</h4>
			<AppLink
				v-if="isMirusUser"
				class="w-full"
				:href="`https://www.statefarm.com/agent/us/xx/_/sf-${agent.associate_id}`"
			>
				(microsite url)
			</AppLink>
			<ul class="grid gap-4">
				<li>
					<div class="font-bold">{{ agent.alias }}</div>
					<div class="text-gray text-sm">Alias</div>
				</li>
				<li>
					<div class="font-bold">{{ agent.associate_id }}</div>
					<div class="text-gray text-sm">Associate ID</div>
				</li>
				<li>
					<div class="font-bold">{{ agent.state_code }}-{{ agent.agent_code }}</div>
					<div class="text-gray text-sm">State-Agent Code</div>
				</li>
				<li>
					<div class="font-bold">{{ phone_number }}</div>
					<div class="text-gray text-sm">Phone Number</div>
				</li>
				<li>
					<div class="font-bold">{{ agent.email_address }}</div>
					<div class="text-gray text-sm">Email Address</div>
				</li>
				<li v-if="agent.redirects.length > 0">
					<div
						v-for="redirect in agent.redirects"
						:key="redirect.redirect_address"
						class="font-bold"
					>
						{{ redirect.redirect_address }}
					</div>
					<div class="text-gray text-sm">Email Redirects</div>
				</li>
				<li>
					<div class="font-bold">
						{{ agent.street }}
						<span v-if="agent.street2">
							<br />
							{{ agent.street2 }}
						</span>
						<br />
						{{ agent.city }}, {{ agent.state }}
						{{ agent.postal_code }}
					</div>
					<div class="text-gray text-sm">Address</div>
				</li>
			</ul>
		</section>

		<section class="flex w-full flex-col gap-1 p-0 md:grow lg:gap-3 2xl:w-1/2">
			<h3 class="mb-0 w-full px-0 text-2xl leading-none">Team Members</h3>
			<hr class="w-full" />

			<div class="grid grid-cols-1 gap-4 xl:grid-cols-2">
				<div
					v-for="aStaff in agent.staff"
					:key="aStaff.associate_id"
					class="rounded border-2 border-gray-200 p-4"
				>
					<h4 class="text-lg font-bold">{{ aStaff.letterhead_name }}</h4>
					<p class="mb-0">{{ aStaff.title || 'Team Member' }}</p>

					<a :href="`mailto:${aStaff.email_address}`" class="w-full truncate">{{
						aStaff.email_address
					}}</a>
					<div v-if="aStaff.redirects.length">
						<FAIcon icon="share" class="mr-1 -scale-y-100 text-gray-500" />
						<a :href="`mailto:${aStaff.redirects[0].redirect_address}`">
							{{ aStaff.redirects[0].redirect_address }}
						</a>
					</div>
					<div class="flex flex-col">
						Associate ID: <span class="font-bold">{{ aStaff.associate_id }}</span>
					</div>
				</div>
			</div>
		</section>
	</div>
	<ProgressBar v-else />
</template>

<script setup>
import { computed } from 'vue';
import useUserStore from '@/stores/user';
import useAgentStore from '@/stores/agent/agent';
import { storeToRefs } from 'pinia';
import ProgressBar from '@/components/ui/ProgressBar';
import AppLink from '@/components/ui/AppLink.vue';

const agentStore = useAgentStore();
const { agent } = storeToRefs(agentStore);

const userStore = useUserStore();
const { user, isMirusUser } = storeToRefs(userStore);
const phone_number = computed(() => {
	return `${agent.value?.phone_number?.slice(0, 3)}-${agent.value?.phone_number?.slice(
		3,
		6
	)}-${agent.value?.phone_number?.slice(6)}`;
});
</script>

<style scoped lang="scss" module>
.icon {
	transform: rotateX(-0.5turn);
}
</style>
