<template>
	<div class="flex flex-col gap-4">
		<div class="flex flex-col gap-4 lg:flex-row">
			<GlobalTimeRangeSelector />

			<InfoLink
				keyword="page_views"
				:show-text="false"
				class="flex items-center justify-center rounded bg-white px-4 dark:bg-gray-600"
			>
				<span>
					<span class="text-xl font-bold leading-none">
						{{ pageViewsTotal }}
					</span>
					<span class="text-md font-normal">Page Views</span>
				</span>
			</InfoLink>
		</div>
		<div class="rounded bg-white p-4 dark:bg-gray-600">
			<InfoLink keyword="page_views_over_time" :show-text="false">
				<span class="text-lg font-medium leading-tight"> Page Views Over Time </span>
			</InfoLink>
			<ProgressBar v-if="loading || !timeGraphOptions" class="my-4 w-full" />
			<Chart v-else class="mt-8 w-full pr-8" :options="timeGraphOptions" />
		</div>
	</div>
</template>
<script setup>
import { computed, watch, ref } from 'vue';
import { format, parseISO } from 'date-fns';
import { Chart } from 'highcharts-vue';
import { storeToRefs } from 'pinia';

import InfoLink from '@/components/glossary/InfoLink';
import GlobalTimeRangeSelector from '@/components/common/GlobalTimeRangeSelector.vue';
import ProgressBar from '@/components/ui/ProgressBar';
import useDomainStore from '@/stores/agent/agent-domains';
import useTimeStore from '@/stores/time';

const props = defineProps({
	domain: {
		type: Object,
		required: true,
	},
});

const domainStore = useDomainStore();
const timeStore = useTimeStore();
const { domainProxyStats } = storeToRefs(domainStore);
const { start: statsStart, end: statsEnd } = storeToRefs(timeStore);

const domainStats = ref(null);
const loading = ref(true);

const pageViewsTotal = computed(() =>
	(domainStats.value?.stats ?? []).reduce((a, b) => a + b.page_views, 0)
);

const timeGraphOptions = computed(() => {
	let timeData = [];
	if (domainStats.value) {
		timeData = domainStats.value.stats;
	} else {
		return null;
	}

	const timeSeries = {
		name: 'Visits',
		data: timeData.map(item => {
			return {
				x: parseISO(item.date),
				y: item.page_views,
			};
		}),
		fillColor: 'rgb(254, 239, 214)',
		color: 'rgb(245,130,32)',
	};
	return {
		chart: {
			type: 'area',
			renderTo: 'time_graph',
			height: '350',
			style: {
				fontFamily: 'Roboto',
			},
		},
		title: { text: undefined },
		credits: false,
		tooltip: {
			formatter: function () {
				const x_string = format(this.x, 'MMM do, yyyy');
				return (
					`<span style="font-size: 10px">${x_string}</span><br/>` +
					`<span style="color:${this.point ? this.point.color : ''}">\u25CF</span> ${
						this.series ? this.series.name : ''
					}: <b>${this.point ? this.point.y : ''}</b><br/>`
				);
			},
		},
		xAxis: {
			type: 'datetime',
		},
		yAxis: {
			title: { text: 'Page Views' },
		},
		series: [timeSeries],
	};
});

watch(
	[statsStart, statsEnd],
	async ([start, end], prevState) => {
		if (start && end && (!prevState || start !== prevState[0] || end !== prevState[1])) {
			loading.value = true;
			await domainStore.getDomainProxyStats({
				domain_name: props.domain.domain_name,
				start: start,
				end: end,
			});
			domainStats.value = domainProxyStats.value[props.domain.domain_name];
			loading.value = false;
		}
	},
	{ immediate: true }
);
</script>
<style scoped></style>
