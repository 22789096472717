<template>
	<div
		v-if="associate_id"
		class="flex items-center"
		:aria-label="`${name}`"
		:draggable="eligible_for_display"
	>
		<BaseTooltip :tooltip-id="`team-member-${associate_id}-edit-image`" text="Edit image">
			<AppLink
				:to="{
					name: 'edit',
					params: { editor: 'team-members' },
					query: { associate_id },
				}"
			>
				<EditorAvatar :image-u-r-l="staffAvatarURL" v-bind="{ initials, name }" />
			</AppLink>
		</BaseTooltip>

		<div class="shrink p-3">
			<h5
				:id="`${associate_id}-name`"
				class="text-wrap text-sm font-medium md:text-base xl:text-lg"
			>
				{{ name }}-{{ associate_id }} ({{ visible ? 'Visible' : 'Hidden' }})
			</h5>

			<p v-if="title" class="mb-0 text-sm leading-tight tracking-tight">
				{{ title }}
			</p>
			<p v-if="!eligible_for_display" class="mt-2 text-red">
				Not displaying on
				<a
					class="red--text underline hover:decoration-solid"
					:href="agent.microsite_url"
					target="_blank"
					rel="noopener noreferrer"
				>
					SF microsite
				</a>
			</p>

			<BaseButton
				color="primary"
				dense
				class="mt-2"
				:to="{
					params: { editor: 'team-members' },
					name: 'edit',
					query: { associate_id: associate_id },
				}"
				rounded="sm"
			>
				Edit member
			</BaseButton>
		</div>
	</div>
	<ProgressBar v-else />
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import useEditorStore from '@/stores/editor';
import useTeam from '@/composables/useTeam';

import ProgressBar from '@/components/ui/ProgressBar';
import BaseTooltip from '@/components/ui/BaseTooltip';
import BaseButton from '@/components/ui/BaseButton.vue';

import EditorAvatar from '@/components/MXEditor/EditorAvatar';
import AppLink from '@/components/ui/AppLink.vue';

const props = defineProps({
	index: {
		type: Number,
		required: true,
	},
	visible: {
		type: Boolean,
		required: true,
	},
	// eslint-disable-next-line vue/prop-name-casing
	eligible_for_display: {
		type: Boolean,
		required: true,
	},
	// eslint-disable-next-line vue/prop-name-casing
	associate_id: {
		type: String,
		required: true,
	},
	initials: {
		type: String,
		reqired: true,
	},
	name: {
		type: String,
		required: true,
	},
	title: {
		type: String,
		required: true,
	},
});

const editorStore = useEditorStore();
const { agent } = storeToRefs(editorStore);
const { pendingStaffAvatars, getCurrentMember } = useTeam();

const pendingImageURL = computed(() => pendingStaffAvatars.value?.[props.associate_id]?.value);

const staffAvatarURL = computed(() => {
	if (pendingImageURL.value) {
		return pendingImageURL.value;
	}
	return getCurrentMember(props.associate_id)?.image;
});
</script>

<style scoped module lang="scss"></style>
