<template>
	<component
		:is="tag"
		class="group relative block w-full rounded-lg text-white"
		@click="navigationStore.hideDrawerOnSmallScreens"
		@keydown.enter="navigationStore.hideDrawerOnSmallScreens"
	>
		<AppLink
			v-slot="{ isActive }"
			class="relative z-0 flex w-full grow flex-nowrap items-center gap-x-2 rounded-lg py-2 !no-underline ring-4 ring-transparent !transition-all hover:bg-gray-600 hover:ring-1 hover:ring-gray-500 hover:ring-offset-0"
			:class="[{ sm: 'pl-2', md: 'pl-4', lg: 'pl-6', xl: 'pl-12' }[indent]]"
			color="white"
			:exact="exact"
			active-class="font-semibold bg-white hover:bg-orange-50"
			v-bind="{
				href,
				to,
			}"
		>
			<div
				:class="isActive ? 'text-black' : 'text-white'"
				class="flex max-w-full items-center"
			>
				<FAIcon v-if="icon" fixed-width :icon="icon" />
				<span
					class="mx-2 mb-0 max-w-full truncate text-ellipsis whitespace-nowrap text-sm font-medium uppercase tracking-widest"
				>
					{{ label }}
				</span>
			</div>
		</AppLink>
	</component>
</template>

<script setup>
import AppLink from '@/components/ui/AppLink.vue';
import useNavigationStore from '@/stores/navigation';

defineProps({
	to: { type: Object, default: null },
	href: { type: String, default: null },

	link: {
		type: [Object, String],
		validator(value) {
			console.error(value);
			return !value;
		},
		default: null,
	},
	indent: { type: String, default: 'sm' },
	disabled: { type: Boolean },
	exact: { type: Boolean, default: true },
	label: { type: String, required: true },
	icon: { type: String, required: false },
	tag: { type: String, default: 'li' },
});
const navigationStore = useNavigationStore();
</script>
<style></style>
